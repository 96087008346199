import React, { useEffect, useState } from "react";
import AdminSidebar from './AdminSidebar'
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import iziToast from 'izitoast';

const BillingDetails = () => {
  const navigate = useNavigate();
  const { apipath, filepath } = useAuth();
  const [billingData, setBillingData] = useState([]);

  // Function to fetch billing data
  const fetchData = async () => {
    try {
      const response = await axios.get(`${apipath}/billing/details`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setBillingData(response.data || []);
    } catch (error) {
      console.error('Error fetching billing:', error);
    }
  };

  // Function to handle billing deletion
//   const handleDelete = async (billingId) => {
//     try {
//       const response = await axios.delete(`http://localhost:4000/api/v1/billing/details/${billingId}`, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       });

//       if (response.status === 200) {
//         // Update the state to remove the deleted billing from the UI
//         setBillingData(prevBillings => prevBillings.filter(billing => billing._id !== billingId));
//         iziToast.success({
//           message: "Details deleted successfully",
//           position: "topCenter",
//         });
//       } else {
//         iziToast.error({
//           message: "Details deletion failed",
//           position: "topCenter",
//         });
//       }
//     } catch (error) {
//       console.error('Error deleting Details:', error);
//       iziToast.error({
//         message: "An error occurred while deleting the Details",
//         position: "topCenter",
//       });
//     }
//   };

  const handleUpdate = async (billingId) => {
    try {
      // Redirect to the billing edit page with the billingId as a parameter
      navigate(`/billingEdit/${billingId}`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <AdminSidebar />
      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Billing Information</h1>
          </div>

          <div className="container-fluid">
            <div className='filter-flex'>
              <div className="input-group search-group">
                <div className="input-group-prepend search-prepend">
                  <span className="input-group-text search-text" id="basic-addon1">
                    <i className="bi bi-search"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control search-control"
                  placeholder="Search by name"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column">
                <div className="container mt-4">
                  <table id="datatable" className="table">
                    <thead>
                      <tr className="tr1">
                        <th>Billing Id</th>
                        <th>User Name</th>
                        <th>Billing Name</th>
                        <th>Phone Number</th>
                        <th>Email Id</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {billingData.map((billing, index) => (
                        <tr key={billing._id}>
                          <td>{index + 1}</td>
                          <td>{billing.userName}</td>
                          <td>{billing.name}</td>
                          <td>{billing.phone}</td> {/* Corrected field name */}
                          <td>{billing.email}</td>
                          <td>
                            <button className="table-btn me-2" onClick={() => handleUpdate(billing._id)}>
                              <i className="bi bi-pencil"></i>
                            </button>
                            <button className="table-btn" >
                            {/* onClick={() => handleDelete(billing._id)}> */}
                              <i className="bi bi-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BillingDetails;
