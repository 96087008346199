import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import ExampleCarouselImage from "./ExampleCarouselImage";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import FAQ from "./FAQ";

const Landing = () => {
  const { apipath, filepath } = useAuth();
  const [homeData, setHomeData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/home-cms/details`); // Adjust the URL as needed
        setHomeData(response.data);
      } catch (error) {
        console.error("Error fetching about data:", error);
      }
    };

    fetchData();
  }, []);

  const cardLinks = [
    "/extrusion?pageType=Extrusion", // Link for the first card
    "/powder?pageType=Powder%20Coating", // Link for the second card
    "/anodizing?pageType=Anodizing", // Link for the third card
    "/fabrication?pageType=Fabrication", // Link for the fourth card
    "/billet?pageType=Billet%20Making", // Link for the fifth card
    "/toolroom?pageType=Tool%20Room", // Link for the sixth card
  ];

  if (!homeData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />

      {/* <div className="container-fluid banner">
        <div className="row banner-row">
          <div className="col-lg-6 banner-left">
            <div className="banner-icons">
              <img src="imgs-alu/logos_facebook.png" alt="" />
              <img src="imgs-alu/skill-icons_instagram.png" alt="" />
              <img src="imgs-alu/logos_twitter.png" alt="" />
              <img src="imgs-alu/devicon_linkedin.png" alt="" />
            </div>
            <h1>SHAPING THE WORLD OF ALUMINIUM FOR A BETTER FUTURE</h1>
            <div className="banner-button">
              <button>Check Our Products</button>
            </div>
          </div>
          <div className="col-lg-6 banner-img">
            <img src="imgs-alu/right-banner.png" alt="" />
          </div>
        </div>
      </div> */}

      <Carousel>
        <Carousel.Item interval={2000}>
          <ExampleCarouselImage
            text={homeData?.banner[0]?.bannertext}
            imgUrl={filepath + homeData?.banner[0]?.bannerImage}
          />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <ExampleCarouselImage
            text={homeData?.banner[1]?.bannertext}
            imgUrl={filepath + homeData?.banner[1]?.bannerImage}
          />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      {/* ABOUT US */}

      {homeData?.body1?.map((item, index) => (
        <div className="container-fluid about" key={index}>
          <div className="main-heading">
            <h1>
              <span>{item.body1heading}</span>
            </h1>
          </div>
          <div className="container">
            <div className="row pt-4">
              <div className="col-lg-6">
                <div className="about-img">
                  <img src={filepath + item.body1image} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-content">
                  <p>{item.body1text}</p>
                  <div className="about-button">
                    <Link to="/about">
                      <button>Learn More</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* CARDS */}

      <div className="container">
        <div className="row card-row">
          {homeData?.body2card?.map((item, index) => (
            <div className="col-lg-4" key={index}>
              <div className="feature-card">
                <div className="feature-img">
                  <img src={filepath + item.cardimage} alt="" />
                </div>
                <h2>{item.cardheading}</h2>
                <p>{item.cardcontent}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* OUR KEY PRODUCTS */}

      <div className="container key">
        <div className="main-heading">
          <h1>
            <span>{homeData?.body3heading}</span>
          </h1>
        </div>
        <div className="row key-row">
          <div className="col-lg-4">
            <Link
              to="/coloring?pageType=Coloring"
              className="text-decoration-none"
            >
              <div className="key-card key-card-1">
                <h3>{homeData?.body3card[0]?.cardtitle}</h3>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link
              to="/designing?pageType=Designing"
              className="text-decoration-none"
            >
              <div className="key-card key-card-2">
                <h3>{homeData?.body3card[1]?.cardtitle}</h3>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link
              to="/anodizing?pageType=Anodizing"
              className="text-decoration-none"
            >
              <div className="key-card key-card-3">
                <h3>{homeData?.body3card[2]?.cardtitle}</h3>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="container-fluid man">
        <div className="main-heading">
          <h1>
            <span>{homeData?.body4heading}</span>
          </h1>
        </div>
        <div className="container">
          <div className="row man-row">
            {homeData?.body4card?.map((item, index) => (
              <div className="col-lg-4" key={index}>
                <div className="man-card">
                  <div className="man-img">
                    <img src={filepath + item.cardimage} alt="" />
                  </div>
                  <h2>{item.cardheading}</h2>
                  <p>{item.cardcontent}</p>
                  <div className="man-button">
                    <Link to={cardLinks[index]}>
                      <button>Details</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container-fluid quality py-5">
        <div className="container flex flex-wrap">
          {homeData?.body5card?.map((item, index) => (
            <div
              className="w-full sm:w-6/12 md:w-4/12 lg:w-3/12 p-2"
              key={index}
            >
              <div className="bg-light-white p-4 border-2 border-white rounded-lg">
                <div className="flex justify-center">
                  <img src={filepath + item.cardimage} alt="" width="100" />
                </div>
                <h3 className="text-white font-josefin-sans font-semibold text-[22px] text-center pt-4">
                  {item.cardtitle}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container-fluid bg-custom-gradient  p-5">
        <div className="container">
          <div className="w-full lg:w-9/12 mx-auto catalogue p-[6%] flex justify-between items-center rounded-lg">
            <div className="main-heading">
              <h1>
                C<span>atalogu</span>e
              </h1>
            </div>
            <div className="animate-bounce w-16 h-16 rounded-full bg-regal-blue flex justify-center items-center cursor-pointer">
              <img src="imgs-alu/ic_round-download.png" width="40" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="main-heading">
          <h1>
            G<span>et in Touch With U</span>s
          </h1>
        </div>
        <p className="font-raleway text-center text-lg text-dark-gray font-medium">
          We are available to help
        </p>

        <form action="">
          <div className="landing-form w-full lg:w-9/12 mx-auto flex flex-wrap">
            <div className="w-full sm:w-6/12 md:w-4/12 lg:w-2/12 p-1 mx-auto">
              <div>
                <input
                  type="text"
                  placeholder="Name"
                  className="w-full p-1 border-1 border-[#D9D9D9]"
                />
              </div>
            </div>
            <div className="w-full sm:w-6/12 md:w-4/12 lg:w-2/12 p-1 mx-auto">
              <div>
                <input
                  type="text"
                  placeholder="Email"
                  className="w-full p-1 border-1 border-[#D9D9D9]"
                />
              </div>
            </div>
            <div className="w-full sm:w-6/12 md:w-4/12 lg:w-2/12 p-1 mx-auto">
              <div>
                <input
                  type="text"
                  placeholder="Phone No."
                  className="w-full p-1 border-1 border-[#D9D9D9]"
                />
              </div>
            </div>
            <div className="w-full sm:w-6/12 md:w-4/12 lg:w-2/12 p-1 mx-auto">
              <div>
                <input
                  type="text"
                  placeholder="Message"
                  className="w-full p-1 border-1 border-[#D9D9D9]"
                />
              </div>
            </div>
            <div className="w-full sm:w-6/12 md:w-4/12 lg:w-2/12 p-1 mx-auto">
              <div>
                <button className=" w-full font-raleway font-medium text-base text-white py-1 bg-dark-yellow">
                  Contact Us
                </button>
              </div>
            </div>
            <div className="container py-5">
            <div className="main-heading">
              <h1>
                T<span>rack Your Order</span>
              </h1>
            </div>
            <div className="text-center">
            <p className="font-raleway text-center text-lg text-dark-gray font-medium">
              Click below to track your order.
            </p>
            <button
              className="font-raleway font-medium text-base text-white py-2 px-4 bg-dark-yellow mt-4"
              onClick={() => window.open("https://script.google.com/a/macros/narayanaluminium.com/s/AKfycbzscKqxlL-kcsbGtVaYeygL2FEXICdg14tuzBrxJz63HFzJJ8gd44EgYL3p7cAaCtEHZg/exec", "_blank")}
            >
              Track Your Order
            </button>
          </div>
          </div>
          </div>
        </form>
      </div>

      <FAQ />

      <Footer />
    </>
  );
};

export default Landing;