import React, { useEffect, useState } from "react";
import axios from "axios";
import AdminSidebar from "./AdminSidebar";
import { Modal, Button, Form } from "react-bootstrap"; // Bootstrap components
import { useAuth } from "../../AuthContext";

const TeamList = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  //member
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showEditTeamMemberModal, setShowEditTeamMemberModal] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);

  //director
  const [showAddDirectorModal, setShowAddDirectorModal] = useState(false);
  const [directors, setDirectors] = useState([]);
  const [selectedDirector, setSelectedDirector] = useState(null);
  const [showEditDirectorModal, setShowEditDirectorModal] = useState(false);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [newMember, setNewMember] = useState({
    name: "",
    designation: "",
    image: null,
  });

  const [newDirector, setNewDirector] = useState({
    name: "",
    designation: "",
    message: "",
    image: null,
  });

  const [isLoading, setIsLoading] = useState(false);

  const { apipath } = useAuth();

  // Fetch all team members
  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get(`${apipath}/team/member/getall`);
      // const response = await axios.get(`http://localhost:4000/api/v1/team/member/getall`);
      setTeamMembers(response.data.teamMembers);
      setFilteredMembers(response.data.teamMembers);
    } catch (error) {
      console.error("Error fetching team members:", error);
    }
  };

  // Handle update for a team member
  const handleUpdateTeamMember = async () => {
    const formData = new FormData();
    formData.append("name", selectedTeamMember.name);
    formData.append("designation", selectedTeamMember.designation);
    if (selectedTeamMember.image instanceof File) {
      formData.append("image", selectedTeamMember.image);
    }

    try {
      setIsLoading(true);
      await axios.put(
        `${apipath}/team/member/update/${selectedTeamMember._id}`,formData,{ headers: { "Content-Type": "multipart/form-data" }});
        // `http://localhost:4000/api/v1/team/member/update/${selectedTeamMember._id}`,formData,{headers: {"Content-Type": "multipart/form-data" }});
      await fetchTeamMembers();
      setShowEditTeamMemberModal(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error updating team member:", error);
      setIsLoading(false);
    }
  };
  // Handle delete action for a team member
  const handleDelete = async (memberId) => {
    try {
      setIsLoading(true);
      await axios.delete(`${apipath}/team/member/delete/${memberId}`);
      // await axios.delete(`http://localhost:4000/api/v1/team/member/delete/${memberId}`);
      await fetchTeamMembers();
      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting team member:", error);
      setIsLoading(false);
    }
  };

  // Handle form submission for adding a new team member
  const handleAddMember = async () => {
    const formData = new FormData();
    formData.append("name", newMember.name);
    formData.append("designation", newMember.designation);
    formData.append("image", newMember.image);

    try {
      setIsLoading(true);
      await axios.post(`${apipath}/team/member/upload`, formData, {headers: { "Content-Type": "multipart/form-data" },});

      // await axios.post(`http://localhost:4000/api/v1/team/member/upload`,formData,{ headers: { "Content-Type": "multipart/form-data" }});
      await fetchTeamMembers();
      setIsLoading(false);
      setShowAddMemberModal(false);
      setNewMember({ name: "", designation: "", image: null });
    } catch (error) {
      console.error("Error adding team member:", error);
      setIsLoading(false);
    }
  };

  // Handle form submission for adding a new director
  const handleAddDirector = async () => {
    const formData = new FormData();
    formData.append("name", newDirector.name);
    formData.append("designation", newDirector.designation);
    formData.append("message", newDirector.message);
    formData.append("image", newDirector.image);

    try {
      setIsLoading(true);
      await axios.post(`${apipath}/team/upload/director`, formData, {headers: { "Content-Type": "multipart/form-data" },});
      // await axios.post(`http://localhost:4000/api/v1/team/upload/director`,formData,{headers: { "Content-Type": "multipart/form-data" },});
      setIsLoading(false);
      setShowAddDirectorModal(false);
      setNewDirector({ name: "", designation: "", message: "", image: null });
    } catch (error) {
      console.error("Error adding director:", error);
      setIsLoading(false);
    }
  };

  // Handle search functionality
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = teamMembers.filter(
      (member) =>
        member.name.toLowerCase().includes(query) ||
        member.designation.toLowerCase().includes(query)
    );

    setFilteredMembers(filtered);
  };
  const fetchDirectors = async () => {
    try {
      const response = await axios.get(`${apipath}/team/directors/getall`);
      // const response = await axios.get(`http://localhost:4000/api/v1/team/directors/getall`);
      setDirectors(response.data);
    } catch (error) {
      console.error("Error fetching directors:", error);
    }
  };

  // Fetch team members on component mount
  useEffect(() => {
    fetchTeamMembers();
    fetchDirectors();
  }, []);

  // Handle update for a director
  const handleUpdateDirector = async () => {
    const formData = new FormData();
    formData.append("name", selectedDirector.name);
    formData.append("designation", selectedDirector.designation);
    formData.append("message", selectedDirector.message);
    if (selectedDirector.image instanceof File) {
      formData.append("image", selectedDirector.image);
    }

    try {
      setIsLoading(true);
      await axios.put(
        `${apipath}/team/directors/update/${selectedDirector._id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      // await axios.put(`http://localhost:4000/api/v1/team//directors/update/${selectedDirector._id}`,formData,{ headers: { "Content-Type": "multipart/form-data"}});
      await fetchDirectors();
      setShowEditDirectorModal(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error updating director:", error);
      setIsLoading(false);
    }
  };

  // Handle delete action for a director
  const handleDeleteDirector = async (directorId) => {
    try {
      setIsLoading(true);
      await axios.delete(`${apipath}/team/director/delete/${directorId}`);
      // await axios.delete(`http://localhost:4000/api/v1/team/directors/delete/${directorId}`);
      await fetchDirectors();
      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting director:", error);
      setIsLoading(false);
    }
  };

  // Pagination logic
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedTeamMembers = teamMembers.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  return (
    <>
      <AdminSidebar />
      <div className="home">
        <div className="dashboard">
          <div className="dashboard-header d-flex justify-content-between align-items-center">
            <h1>Team Management</h1>
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control me-2"
                placeholder="Search by name or designation"
                value={searchQuery}
                onChange={handleSearch}
              />
              <Button
                variant="primary"
                style={{ height: "35px", width: "200px" }}
                size="sm"
                className="btn-xs me-2"
                onClick={() => setShowAddMemberModal(true)}
              >
                Add Member
              </Button>
              <Button
                variant="success"
                style={{ height: "35px", width: "200px" }}
                size="sm"
                className="btn-xs"
                onClick={() => setShowAddDirectorModal(true)}
              >
                Add Director
              </Button>
            </div>
          </div>

          {/* Directors Table */}
          <h2 className="mt-4">Directors</h2>
          <table className="table mt-3">
            <thead>
              <tr>
                <th>Name</th>
                <th>Designation</th>
                <th>Message</th>
                <th>Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {directors.map((director) => (
                <tr key={director._id}>
                  <td>{director.name}</td>
                  <td>{director.designation}</td>
                  <td>{director.message}</td>
                  <td>
                    <img
                      src={`${apipath}${director.image}`}
                      alt={director.name}
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                      }}
                    />
                  </td>
                  <td>
                    {/* <button
                      className="btn btn-sm"
                      onClick={() => setSelectedDirector(director)}
                    >
                      <i className="bi bi-eye"></i> View
                    </button> */}
                    <button
                      className="btn btn-sm "
                      onClick={() => {
                        setSelectedDirector(director);
                        setShowEditDirectorModal(true);
                      }}
                    >
                      <i className="bi bi-pencil"></i> Edit
                    </button>
                    <button
                      className="btn btn-sm"
                      onClick={() => handleDeleteDirector(director._id)}
                      disabled={isLoading}
                    >
                      <i className="bi bi-trash"></i> Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Team Members Table */}
          <h2 className="mt-4">Team Members</h2>
          <table className="table mt-3">
            <thead>
              <tr>
                <th>#</th> {/* Serial Number Column */}
                <th>Name</th>
                <th>Designation</th>
                <th>Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredMembers.map((member, index) => (
                <tr key={member._id}>
                  <td>{index + 1}</td> {/* Serial number */}
                  <td>{member.name}</td>
                  <td>{member.designation}</td>
                  <td>
                    <img
                      src={`${apipath}${member.image}`}
                      alt={member.name}
                      style={{ width: "50px" }}
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-sm"
                      onClick={() => setSelectedMember(member)} // Set the selected member
                    >
                      <i className="bi bi-eye"></i> View
                    </button>
                    <button
                      className="btn btn-sm "
                      onClick={() => {
                        setSelectedTeamMember(member);
                        setShowEditTeamMemberModal(true);
                      }}
                    >
                      <i className="bi bi-pencil"></i> Edit
                    </button>
                    <button
                      className="btn btn-sm "
                      onClick={() => handleDelete(member._id)}
                      disabled={isLoading}
                    >
                      <i className="bi bi-trash text-enquiry-button bg-transparent"></i>{" "}
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Pagination Controls */}
      <div className="flex justify-center items-center mt-4">
        <button
          className="btn btn-sm btn-secondary"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="mx-2">
          Page {currentPage} of {Math.ceil(teamMembers.length / rowsPerPage)}
        </span>
        <button
          className="btn btn-sm btn-secondary"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === Math.ceil(teamMembers.length / rowsPerPage)}
        >
          Next
        </button>
      </div>

      {/* Modal for adding a new team member */}
      <Modal
        show={showAddMemberModal}
        onHide={() => setShowAddMemberModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Team Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={newMember.name}
                onChange={(e) =>
                  setNewMember({ ...newMember, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Designation</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter designation"
                value={newMember.designation}
                onChange={(e) =>
                  setNewMember({ ...newMember, designation: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    const isValidType =
                      file.type === "image/jpeg" || file.type === "image/jpg";
                    if (!isValidType) {
                      alert("Only JPEG/JPG images are allowed.");
                      e.target.value = ""; // Clear the input
                      return;
                    }
                    setNewMember({ ...newMember, image: file });
                  }
                }}
              />
              <p className="text-muted">Image should be JPG/JPEG (1:1 ratio)</p>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAddMemberModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleAddMember}
            disabled={isLoading}
          >
            {isLoading ? "Adding..." : "Add Member"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for adding a new director */}
      <Modal
        show={showAddDirectorModal}
        onHide={() => setShowAddDirectorModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Director</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={newDirector.name}
                onChange={(e) =>
                  setNewDirector({ ...newDirector, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Designation</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter designation"
                value={newDirector.designation}
                onChange={(e) =>
                  setNewDirector({
                    ...newDirector,
                    designation: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Message (Max 50 words)</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter message"
                value={newDirector.message}
                onChange={(e) => {
                  const words = e.target.value.split(" ").filter(Boolean);
                  if (words.length <= 50) {
                    setNewDirector({ ...newDirector, message: e.target.value });
                  }
                }}
              />
              <Form.Text>
                {50 - newDirector.message.split(" ").filter(Boolean).length}{" "}
                words remaining
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    const isValidType =
                      file.type === "image/jpeg" || file.type === "image/jpg";
                    if (!isValidType) {
                      alert("Only JPEG/JPG images are allowed.");
                      e.target.value = ""; // Clear the input
                      return;
                    }
                    setNewDirector({
                      ...newDirector,
                      image: e.target.files[0],
                    });
                  }
                }}
              />
              <p className="text-muted">Image should be JPG/JPEG (1:1 ratio)</p>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAddDirectorModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleAddDirector}
            disabled={isLoading}
          >
            {isLoading ? "Adding..." : "Add Director"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for editing a director */}
      <Modal
        show={showEditDirectorModal}
        onHide={() => setShowEditDirectorModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Director</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedDirector && (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedDirector.name}
                  onChange={(e) =>
                    setSelectedDirector({
                      ...selectedDirector,
                      name: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Designation</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedDirector.designation}
                  onChange={(e) =>
                    setSelectedDirector({
                      ...selectedDirector,
                      designation: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={selectedDirector.message}
                  onChange={(e) =>
                    setSelectedDirector({
                      ...selectedDirector,
                      message: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Image</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    setSelectedDirector({
                      ...selectedDirector,
                      image: e.target.files[0],
                    })
                  }
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowEditDirectorModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleUpdateDirector}
            disabled={isLoading}
          >
            {isLoading ? "Updating..." : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={selectedMember !== null}
        onHide={() => setSelectedMember(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Team Member Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMember && (
            <>
              <div>
                <strong>Name:</strong> {selectedMember.name}
              </div>
              <div>
                <strong>Designation:</strong> {selectedMember.designation}
              </div>

              <div>
                <strong>Image:</strong>
                <img
                  src={`${selectedMember.image.replace(/\\/g, "/")}`}
                  alt={selectedMember.name}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSelectedMember(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Team Member Modal */}
      <Modal
        show={showEditTeamMemberModal}
        onHide={() => setShowEditTeamMemberModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Team Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTeamMember && (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedTeamMember.name}
                  onChange={(e) =>
                    setSelectedTeamMember({
                      ...selectedTeamMember,
                      name: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Designation</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedTeamMember.designation}
                  onChange={(e) =>
                    setSelectedTeamMember({
                      ...selectedTeamMember,
                      designation: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Image</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    setSelectedTeamMember({
                      ...selectedTeamMember,
                      image: e.target.files[0],
                    })
                  }
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowEditTeamMemberModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleUpdateTeamMember}
            disabled={isLoading}
          >
            {isLoading ? "Updating..." : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TeamList;
