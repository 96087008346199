// import React, { useState } from "react";
// // import axios from "axios";

// const EnquiryForm = () => {
//     const [show, setShow] = useState(1);

//     const handleShow = (index) => {
//         setShow(show === index ? null : index);
//       };

//   return (

//     <div className="container-fluid bg-sky-blue py-5 mb-5">
//         <div className="container">
//           <div class="row faq-row">
//             <div class="col-lg-6">
//               <div class="faq-left">
//                 <h1>
//                   Frequently Asked Questions <span>(FAQ)</span>
//                 </h1>
//                 <hr />
//                 <p>
//                   Explore answers to commonly asked questions about our
//                   platform, services, and product experience.
//                 </p>
//               </div>
//             </div>
//             <div class="col-lg-6 faq-cards">
//               <div class="faq-card">
//                 <div class="faq-heading">
//                   <h3>
//                     Can you produce the sheets according to our requirements ?
//                   </h3>
//                   <i
//                     class={show === 1 ? "bi bi-dash" : "bi bi-plus"}
//                     onClick={() => handleShow(1)}
//                   ></i>
//                 </div>
//                 {show === 1 && (
//                   <p class="faq-p">
//                     Yes, at Narayan Aluminium Alloy Pvt. Ltd., we pride
//                     ourselves on our flexibility and ability to tailor our
//                     designs to meet your specific needs. Whether it's
//                     customization of existing models or the development of
//                     entirely new sheets, our expert team is equipped to fulfill
//                     your requirements with precision and excellence.
//                   </p>
//                 )}
//               </div>
//               <div class="faq-card">
//                 <div class="faq-heading">
//                   <h3>
//                     Can you produce the sheets according to our requirements ?
//                   </h3>
//                   <i
//                     class={show === 2 ? "bi bi-dash" : "bi bi-plus"}
//                     onClick={() => handleShow(2)}
//                   ></i>
//                 </div>
//                 {show === 2 && (
//                   <p class="faq-p">
//                     Yes, at Narayan Aluminium Alloy Pvt. Ltd., we pride
//                     ourselves on our flexibility and ability to tailor our
//                     designs to meet your specific needs. Whether it's
//                     customization of existing models or the development of
//                     entirely new sheets, our expert team is equipped to fulfill
//                     your requirements with precision and excellence.
//                   </p>
//                 )}
//               </div>
//               <div class="faq-card">
//                 <div class="faq-heading">
//                   <h3>
//                     Can you produce the sheets according to our requirements ?
//                   </h3>
//                   <i
//                     class={show === 3 ? "bi bi-dash" : "bi bi-plus"}
//                     onClick={() => handleShow(3)}
//                   ></i>
//                 </div>
//                 {show === 3 && (
//                   <p class="faq-p">
//                     Yes, at Narayan Aluminium Alloy Pvt. Ltd., we pride
//                     ourselves on our flexibility and ability to tailor our
//                     designs to meet your specific needs. Whether it's
//                     customization of existing models or the development of
//                     entirely new sheets, our expert team is equipped to fulfill
//                     your requirements with precision and excellence.
//                   </p>
//                 )}
//               </div>
//               <div class="faq-card">
//                 <div class="faq-heading">
//                   <h3>
//                     Can you produce the sheets according to our requirements ?
//                   </h3>
//                   <i
//                     class={show === 4 ? "bi bi-dash" : "bi bi-plus"}
//                     onClick={() => handleShow(4)}
//                   ></i>
//                 </div>
//                 {show === 4 && (
//                   <p class="faq-p">
//                     Yes, at Narayan Aluminium Alloy Pvt. Ltd., we pride
//                     ourselves on our flexibility and ability to tailor our
//                     designs to meet your specific needs. Whether it's
//                     customization of existing models or the development of
//                     entirely new sheets, our expert team is equipped to fulfill
//                     your requirements with precision and excellence.
//                   </p>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//   );
// };

// export default EnquiryForm;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from "../../AuthContext";

const FAQForm = () => {
    const { apipath } = useAuth();
    const [show, setShow] = useState(null);

    const [faqData, setFAQData] = useState(null);

    const handleShow = (index) => {
        setShow(show === index ? null : index);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apipath}/faq/all`); // Adjust the URL as needed
                setFAQData(response.data);
                // console.log(response.data);
            } catch (error) {
                console.error('Error fetching about data:', error);
            }
        };

        fetchData();
    }, []);

    if (!faqData) {
        return <div>Loading...</div>;
    }

    return (

        <div className="container-fluid bg-sky-blue py-5 mb-5">
            <div className="container">
                <div class="row faq-row">
                    <div class="col-lg-6">
                        <div class="faq-left">
                            <h1>
                                {faqData.maintitle} <span>(FAQ)</span>
                            </h1>
                            <hr />
                            <p>
                                {faqData.statement}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 faq-cards">
                        {faqData.QA.map((item, index) => (
                            <div class="faq-card" key={index}>
                                <div class="faq-heading">
                                    <h3>
                                        {item.question}
                                    </h3>
                                    <i
                                        class={show === index ? "bi bi-dash" : "bi bi-plus"}
                                        onClick={() => handleShow(index)}
                                    ></i>
                                </div>
                                {show === index && (
                                    <p class="faq-p">
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default FAQForm;