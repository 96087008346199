import React, { useEffect, useState } from "react";
import AdminSidebar from './AdminSidebar'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import iziToast from 'izitoast';
import { useAuth } from '../../AuthContext';

const EnquireDetails = () => {

  const navigate = useNavigate();
  const [enquireData, setEnquireData] = useState([]);
  const { apipath } = useAuth();

  // Function to fetch enquire data
  const fetchData = async () => {
    try {
      const response = await axios.get(`${apipath}/enquireNow/details`);
      setEnquireData(response.data.enquireNow || []);
    } catch (error) {
      console.error('Error fetching enquireNow:', error);
    }
  };

   // Function to handle enquire deletion
  const handleDelete = async (enquireId) => {
    try {
      const response = await axios.delete(`${apipath}/enquireNow/details/${enquireId}`);
      
      if (response.status === 200) {
        // Update the state to remove the deleted enquire from the UI
        setEnquireData(prevEnquires => prevEnquires.filter(enquire => enquire._id !== enquireId));
        
        // Show success notification
        iziToast.success({
          message: "Details deleted successfully",
          position: "topCenter",
        });
        
        navigate("/enquireDetails");
      } else {
        // Show error notification if deletion failed
        iziToast.error({
          message: "details deletion failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error('Error deleting contact:', error);
      // Show error notification if an error occurred
      iziToast.error({
        message: "An error occurred while deleting the contact",
        position: "topCenter",
      });
    }
  };


  const handleUpdate = async (enquireId) => {
    try {
      // Redirect to the user details page with the productId as a parameter
      navigate(`/enquireEdit/${enquireId}`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
        <AdminSidebar />
        <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>
            Enquiry Management</h1>
          </div>

          <div className="container-fluid">          
          <div className='filter-flex'>
            <div class="input-group search-group">
              <div class="input-group-prepend search-prepend">
                <span class="input-group-text search-text" id="basic-addon1">
                  <i className="bi bi-search"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control search-control"
                placeholder="Search by name"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            </div>
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column">
                <div className="container mt-4">
                  <table id="datatable" className="table">
                    <thead>
                      <tr className="tr1">
                      <th>S.No.</th>
                        <th>Name</th>
                        <th>Phone No.</th>
                        <th>Email Id</th>
                        <th>address</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {enquireData.map((enquire, index) => (
                        <tr key={enquire._id}>
                        <td>{index + 1}</td>
                          <td>{enquire.name}</td>
                          <td>{enquire.phoneNo}</td>
                          <td>{enquire.email}</td>
                          <td>{enquire.address}</td>
                          <td>
                          <button className="table-btn me-2"
                              onClick={() => handleUpdate(enquire._id)}>
                              <i className="bi bi-pencil"></i>
                            </button>
                            <button className="table-btn" onClick={() => handleDelete(enquire._id)}>
                              <i className="bi bi-trash"></i>
                            </button>
                        </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EnquireDetails