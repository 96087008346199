// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from 'react-router-dom';
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { useAuth } from "../../AuthContext";
// import axios from "axios";
// import iziToast from 'izitoast';

// const Cart = () => {
//   const [cart, setCart] = useState([]);
//   const { apipath, filepath } = useAuth();
//   const navigate = useNavigate();

//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "auto",
//     });
//   }, []);

//   useEffect(() => {
//     const fetchCart = async () => {
//       try {
//         const token = localStorage.getItem('token');
//         const response = await axios.get(`${apipath}/cart/details`, {
//           headers: { Authorization: `Bearer ${token}` }
//         });
//         setCart(response.data.cart.items);
//       } catch (error) {
//         console.error('Error fetching cart details:', error);
//       }
//     };

//     fetchCart();
//   }, [apipath]);

//   const updateCartItem = async (itemId, newQuantity) => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await axios.put(`${apipath}/cart/update`, { itemId, quantity: newQuantity }, {
//         headers: { Authorization: `Bearer ${token}` }
//       });

//       console.log('Update response:', response.data);

//       setCart(response.data.cart.items);
//     } catch (error) {
//       console.error('Error updating cart item:', error);
//     }
//   };

//   const removeItem = async (itemId) => {
//     try {
//       const token = localStorage.getItem('token');
//       await axios.delete(`${apipath}/cart/delete/${itemId}`, {
//         headers: { Authorization: `Bearer ${token}` }
//       });
//       setCart(cart.filter(item => item._id !== itemId));
//     } catch (error) {
//       console.error('Error removing cart item:', error);
//     }
//   };

//   const handleProceedToPay = () => {
//     if (cart.length === 0) {
//       iziToast.error({
//         title: 'Error',
//         message: "Sorry, can't proceed. Your cart is empty.",
//         position: 'topCenter'
//       });
//     } else {
//       navigate('/billing');
//     }
//   };

//   return (
//     <>
//       <Navbar />
//       <Link to="/" className="float">
//         <img src="imgs-alu/whtsp.png" alt="WhatsApp" />
//       </Link>
//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//             Cart
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home <i className="bi bi-chevron-double-right text-light-yellow"></i> Cart
//           </h4>
//         </div>
//       </div>
//       <div className="container py-5">
//         <div className="w-full lg:w-10/12 mx-auto">
//           <div className="w-full">
//             <div className="flex justify-between p-2 border-b border-[#a1a1a1]">
//               <h1 className="font-poppins font-semibold text-3xl">
//                 Shopping Cart
//               </h1>
//               <h1 className="font-poppins font-semibold text-3xl">
//                 {cart.length} Items
//               </h1>
//             </div>
//             <table className="w-full mt-3">
//               <thead>
//                 <tr className="border-b border-[#a1a1a1] bg-transparent font-jost text-base font-medium">
//                   <th className="p-2 bg-transparent">Product Details</th>
//                   <th className="p-2 bg-transparent">Quantity</th>
//                   <th className="p-2 bg-transparent">Price</th>
//                   <th className="p-2 bg-transparent">Total</th>
//                   <th className="p-2 bg-transparent">Remove</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {cart.map((item) => (
//                   <tr className="border-b border-[#a1a1a1]" key={item._id}>
//                     <td>
//                       <div className="flex items-center font-poppins text-sm font-normal text-dark-gray">
//                         <img
//                           src={item.productPic ? filepath + item.productPic : `${process.env.PUBLIC_URL}/assets/products.png`}
//                           alt={item.productName}
//                           className="w-20 h-20 mr-3"
//                         />
//                         <p>{item.productName}</p>
//                       </div>
//                     </td>
//                     <td>
//                       <div className="flex items-center">
//                         <span>
//                           <i
//                             className="bi bi-plus cursor-pointer"
//                             onClick={() => updateCartItem(item._id, item.quantity + 1)}
//                           ></i>
//                         </span>
//                         <h3 className="px-3 pt-2 font-poppins text-sm font-medium text-dark-gray">
//                           {item.quantity}
//                         </h3>
//                         <span>
//                           <i
//                             className="bi bi-dash cursor-pointer"
//                             onClick={() => updateCartItem(item._id, item.quantity - 1)}
//                           ></i>
//                         </span>
//                       </div>
//                     </td>
//                     <td className="font-poppins text-sm font-normal text-dark-gray">
//                       ${item.price}
//                     </td>
//                     <td className="font-poppins text-sm font-normal text-dark-gray">
//                       ${item.price * item.quantity}
//                     </td>
//                     <td>
//                       <button
//                         onClick={() => removeItem(item._id)}
//                         className="px-3 py-2 bg-remove-yellow border-1 border-enquiry-button rounded-[10px] font-poppins font-medium text-base text-enquiry-button"
//                       >
//                         <i className="bi bi-trash text-enquiry-button bg-transparent"></i> Delete
//                       </button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>
//         <div className="flex justify-center my-14">
//           {/* <Link to="/billing"> */}
//             <button onClick={handleProceedToPay} className="py-2 px-12 rounded-pill bg-enquiry-button font-poppins font-medium text-white text-lg border-1 border-enquiry-button hover:bg-transparent hover:!text-enquiry-button hover:ease-in-out duration-300">
//               Proceed To Pay
//             </button>
//           {/* </Link> */}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Cart;


import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import iziToast from 'izitoast';

const Cart = () => {
  const [cart, setCart] = useState([]);
  const { apipath, filepath } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  useEffect(() => {
    const fetchCart = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${apipath}/cart/details`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setCart(response.data.cart.items);
      } catch (error) {
        console.error('Error fetching cart details:', error);
      }
    };

    fetchCart();
  }, [apipath]);

  const updateCartItem = async (itemId, newQuantity) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(`${apipath}/cart/update`, { itemId, quantity: newQuantity }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      console.log('Update response:', response.data);

      setCart(response.data.cart.items);
    } catch (error) {
      console.error('Error updating cart item:', error);
    }
  };

  const removeItem = async (itemId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${apipath}/cart/delete/${itemId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCart(cart.filter(item => item._id !== itemId));
    } catch (error) {
      console.error('Error removing cart item:', error);
    }
  };

  const handleProceedToPay = () => {
    if (cart.length === 0) {
      iziToast.error({
        title: 'Error',
        message: "Sorry, can't proceed. Your cart is empty.",
        position: 'topCenter'
      });
    } else {
      navigate('/billing', { state: { cart } });
    }
  };

  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="WhatsApp" />
      </Link>
      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Cart
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home <i className="bi bi-chevron-double-right text-light-yellow"></i> Cart
          </h4>
        </div>
      </div>
      <div className="container py-5">
        <div className="w-full lg:w-10/12 mx-auto">
          <div className="w-full">
            <div className="flex justify-between p-2 border-b border-[#a1a1a1]">
              <h1 className="font-poppins font-semibold text-3xl">
                Shopping Cart
              </h1>
              <h1 className="font-poppins font-semibold text-3xl">
                {cart.length} Items
              </h1>
            </div>
            <table className="w-full mt-3">
              <thead>
                <tr className="border-b border-[#a1a1a1] bg-transparent font-jost text-base font-medium">
                  <th className="p-2 bg-transparent">Product Details</th>
                  <th className="p-2 bg-transparent">Quantity</th>
                  <th className="p-2 bg-transparent">Price</th>
                  <th className="p-2 bg-transparent">Total</th>
                  <th className="p-2 bg-transparent">Remove</th>
                </tr>
              </thead>
              <tbody>
                {cart.map((item) => (
                  <tr className="border-b border-[#a1a1a1]" key={item._id}>
                    <td>
                      <div className="flex items-center font-poppins text-sm font-normal text-dark-gray">
                        <img
                          src={item.productPic ? filepath + item.productPic : `${process.env.PUBLIC_URL}/assets/products.png`}
                          alt={item.productName}
                          className="w-20 h-20 mr-3"
                        />
                        <p>{item.productName}</p>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center">
                        <span>
                          <i
                            className="bi bi-plus cursor-pointer"
                            onClick={() => updateCartItem(item._id, item.quantity + 1)}
                          ></i>
                        </span>
                        <h3 className="px-3 pt-2 font-poppins text-sm font-medium text-dark-gray">
                          {item.quantity}
                        </h3>
                        <span>
                          <i
                            className="bi bi-dash cursor-pointer"
                            onClick={() => updateCartItem(item._id, item.quantity - 1)}
                          ></i>
                        </span>
                      </div>
                    </td>
                    <td className="font-poppins text-sm font-normal text-dark-gray">
                    ₹{item.price}
                    </td>
                    <td className="font-poppins text-sm font-normal text-dark-gray">
                    ₹{item.price * item.quantity}
                    </td>
                    <td>
                      <button
                        onClick={() => removeItem(item._id)}
                        className="px-3 py-2 bg-remove-yellow border-1 border-enquiry-button rounded-[10px] font-poppins font-medium text-base text-enquiry-button"
                      >
                        <i className="bi bi-trash text-enquiry-button bg-transparent"></i> Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-center my-14">
          <button onClick={handleProceedToPay} className="py-2 px-12 rounded-pill bg-enquiry-button font-poppins font-medium text-white text-lg border-1 border-enquiry-button hover:bg-transparent hover:!text-enquiry-button hover:ease-in-out duration-300">
            Proceed To Pay
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Cart;
