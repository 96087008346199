import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useAuth } from "../../AuthContext";
import axios from "axios";

const ProductList = () => {
  const navigate = useNavigate();
  const { apipath, filepath, isAuthenticated } = useAuth(); // Assuming isAuthenticated is provided by useAuth
  const [productData, setProductData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  // Function to fetch product data
  const fetchData = async () => {
    try {
      const response = await axios.get(`${apipath}/product/details`);
      setProductData(response.data.product || []);
      setFilteredData(response.data.product || []);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Handle search and filter
  useEffect(() => {
    let data = productData;

    if (searchTerm) {
      data = data.filter(product =>
        product.productName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (selectedCategory) {
      data = data.filter(product => product.category === selectedCategory);
    }

    setFilteredData(data);
  }, [searchTerm, selectedCategory, productData]);

  // Redirect to login if not authenticated
  // Function to handle add to cart click
  const handleProductClick = (productId) => {
    const token = localStorage.getItem('token');

    if (token) {
      navigate(`/product-detail/${productId}`);
    } else {
      localStorage.setItem('redirectAfterLogin', `/product-detail/${productId}`);
      navigate('/login');
    }
  };

  // Get unique categories
  const uniqueCategories = [...new Set(productData.map(product => product.category))];

  return (
    <>
      <Navbar />

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            E-Catalogue
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            E-catalogue
          </h4>
        </div>
      </div>

      <div className="container-fluid pt-5 pb-2 bg-yellow-2">
        <div className="container">
          <div className="w-full z-10">
            <h2 className="font-raleway text-2xl font-semibold text-left text-[#252525]">
              Search by Categories or Section :
            </h2>
            <form className="w-full flex flex-wrap my-5">
              <div className="w-full lg:w-1/2 pr-2 mb-2">
                <select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="w-full py-2 px-3 bg-white opacity-[70%] rounded-[10px] font-nunito font-medium text-lg text-input-gray"
                >
                  {/* <option value="">Select Category</option>
                  {filteredData.map((product) => (
                    <option key={product._id} value={product.category}>
                      {product.category}
                    </option>
                  ))} */}
                  <option value="">Select Category</option>
                  {uniqueCategories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex lg:w-1/2 w-full py-2 px-3 bg-white opacity-[70%] rounded-[10px] mb-2">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full bg-transparent rounded-[10px] focus:outline-none font-normal font-nunito text-lg text-input-gray"
                  placeholder="Search Products"
                />
                <span>
                  <i className="bi bi-search"></i>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="container my-5 pb-2 flex justify-between border-b border-[#C0C0C0]">
        <div className="font-jost text-base font-medium">
          <span className="text-enquiry-button">Category </span>
          <i className="bi bi-chevron-right text-enquiry-button"></i>
          <span className="text-black"> Channel </span>
        </div>
        <div>
          <span className="font-jost text-base font-medium">Total: {productData.length}</span>
        </div>
      </div>

      <div className="container flex flex-wrap">
        {filteredData.map((product) => (
          <div key={product._id} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
            <div className="rounded-2xl bg-white shadow-custom-contact p-3">
              <div onClick={() => handleProductClick(product._id)} style={{ cursor: "pointer" }}>
                <img src={product.productPic !== '' && product.productPic !== null ? filepath + product.productPic : `${process.env.PUBLIC_URL}/assets/products.png`} alt={product.productName} className="w-full h-[150px]" />
              </div>
              <div className="pt-2 flex justify-between items-center">
                <div className="font-nunito text-xs font-normal text-dark-gray">
                  ID : {product.sectionId}
                </div>
                {/* Checkbox and label */}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="container my-5 pt-3 flex justify-between border-t border-[#C0C0C0]">
        <div className="font-jost text-base font-medium text-gray-3">
          <span>Page 1 of 2</span>
        </div>
        <div>
          <button className="font-poppins text-base font-medium rounded-pill px-4 py-1 bg-enquiry-button text-white">
            Next
          </button>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ProductList;
