import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";
import iziToast from "izitoast";
import { useAuth } from "../../AuthContext";

const Profile = () => {
  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(!edit);
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { apipath } = useAuth();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    userId: "",
    name: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    address: "",
    city: "",
    state: "",
    country: "",
    dob: "",
  });

  const userId = localStorage.getItem('userId');

  // Function to check if user is logged in
  const checkLoginStatus = () => {
    const token = localStorage.getItem('usertoken');
    setIsLoggedIn(!!token); // Set login status based on token presence
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`${apipath}/users/details/${userId}`);
        if (response.data.user) {
          const user = response.data.user;
          if (user.dob) {
            // Format the dob to YYYY-MM-DD
            const formattedDob = new Date(user.dob).toISOString().split('T')[0];
            setUserDetails({
              ...user,
              dob: formattedDob,
            });
          } else {
            // If dob does not exist, set the user details without dob
            setUserDetails(user);
          }
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [userId]);


  useEffect(() => {
    checkLoginStatus();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Convert dob to timestamp
    const { dob, ...otherDetails } = userDetails;
    const dobTimestamp = new Date(dob).getTime();
    const userDetailsWithTimestamp = { ...otherDetails, dob: dobTimestamp };

    try {
      const response = await axios.put(
        `${apipath}/users/details/${userId}`,
        userDetailsWithTimestamp,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        iziToast.success({
          title: 'Success',
          message: 'Profile updated successfully',
          position: 'topCenter',
          timeout: 2000,
        });
        navigate("/");
      } else {
        throw new Error('Failed to update profile');
      }
    } catch (error) {
      console.error('Error submitting user details:', error);
      iziToast.error({
        title: 'Error',
        message: 'Failed to update profile. Please try again later.',
        position: 'topCenter',
        timeout: 2000,
      });
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} />
      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            My Profile
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            My Profile
          </h4>
        </div>
      </div>

      <div className="container py-4">
        <div className="w-full lg:w-8/12 px-5 py-3 my-4 mx-auto bg-light-blue rounded-xl shadow-custom-contact">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div>
                <img src={`${process.env.PUBLIC_URL}/imgs-alu/profile.png`} alt="" className="w-16 h-16" />
              </div>
              <div className="mx-4 pt-4">
                <h5 className="font-poppins text-xl font-semibold text-left">
                  {userDetails.name}{" "}{userDetails.lastName}
                </h5>
                <p className="font-poppins text-base font-medium text-left text-profile-gray">
                  {userDetails.email}
                </p>
              </div>
            </div>
            <div>
              <span
                className="font-poppins text-sm font-normal text-center text-profile-gray px-3 py-1 rounded-pill border-1 border-profile-gray cursor-pointer"
                onClick={handleEdit}
              >
                Edit
              </span>
            </div>
          </div>
          <h5>Personal Information</h5>
          {edit ? (
            <div className="w-full flex flex-wrap mt-3">
              <form className="w-full flex flex-wrap" onSubmit={handleSubmit}>
                <div className="w-full lg:w-1/2 p-2 mb-2">
                  <div>
                    <label
                      htmlFor=""
                      className="block font-poppins font-medium text-base mb-1 text-profile-item-gray"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      placeholder="First Name"
                      className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                      name="name"
                      value={userDetails.name}
                      onChange={handleInputChange}
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-1/2 p-2 mb-2">
                  <div>
                    <label
                      htmlFor=""
                      className="block font-poppins font-medium text-base mb-1 text-profile-item-gray"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"

                      name="lastName"
                      value={userDetails.lastName}
                      onChange={handleInputChange}
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-1/2 p-2 mb-2">
                  <div>
                    <label
                      htmlFor=""
                      className="block font-poppins font-medium text-base mb-1 text-profile-item-gray"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      placeholder="Enter Email"
                      className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"

                      name="email"
                      value={userDetails.email}
                      onChange={handleInputChange}
                      maxLength="100"
                      readOnly
                    />
                  </div>
                </div>
                <div className="w-full lg:w-1/2 p-2 mb-2">
                  <div>
                    <label
                      htmlFor=""
                      className="block font-poppins font-medium text-base mb-1 text-profile-item-gray"
                    >
                      Phone No.
                    </label>
                    <input
                      type="text"
                      placeholder="Phone"
                      className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                      name="phone"
                      value={userDetails.phone}
                      onChange={handleInputChange}
                      maxLength="15"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-1/2 p-2 mb-2">
                  <div>
                    <label
                      htmlFor=""
                      className="block font-poppins font-medium text-base mb-1 text-profile-item-gray"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      placeholder="Address"
                      className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                      name="address"
                      value={userDetails.address}
                      onChange={handleInputChange}
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-1/2 p-2 mb-2">
                  <div>
                    <label
                      htmlFor=""
                      className="block font-poppins font-medium text-base mb-1 text-profile-item-gray"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      placeholder="City"
                      className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                      name="city"
                      value={userDetails.city}
                      onChange={handleInputChange}
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-1/2 p-2 mb-2">
                  <div>
                    <label
                      htmlFor=""
                      className="block font-poppins font-medium text-base mb-1 text-profile-item-gray"
                    >
                      State
                    </label>
                    <input
                      type="text"
                      placeholder="State"
                      className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                      name="state"
                      value={userDetails.state}
                      onChange={handleInputChange}
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-1/2 p-2 mb-2">
                  <div>
                    <label
                      htmlFor=""
                      className="block font-poppins font-medium text-base mb-1 text-profile-item-gray"
                    >
                      Country
                    </label>
                    <input
                      type="text"
                      placeholder="Country"
                      className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                      name="country"
                      value={userDetails.country}
                      onChange={handleInputChange}
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-1/2 p-2 mb-2">
                  <div>
                    <label
                      htmlFor=""
                      className="block font-poppins font-medium text-base mb-1 text-profile-item-gray"
                    >
                      Company Name
                    </label>
                    <input
                      type="text"
                      placeholder="Orders"
                      className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"

                      name="companyName"
                      value={userDetails.companyName}
                      onChange={handleInputChange}
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-1/2 p-2 mb-2">
                  <div>
                    <label
                      htmlFor=""
                      className="block font-poppins font-medium text-base mb-1 text-profile-item-gray"
                    >
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      placeholder="DOB"
                      className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                      name="dob"
                      value={userDetails.dob}
                      onChange={handleInputChange}
                      maxLength="15" />
                  </div>
                </div>
                <div className="w-full flex justify-center my-2">
                  <button className="py-1 px-8 rounded-lg bg-enquiry-button font-poppins font-medium text-white text-lg border-1 border-enquiry-button hover:bg-transparent hover:!text-enquiry-button hover:ease-in-out duration-300">
                    Update
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div className="w-full flex flex-wrap mt-3">
              <div className="w-full lg:w-1/2">
                <div className="font-poppins text-base font-medium text-left">
                  <p className="text-profile-item-gray">First Name</p>
                  <p className=" mt-[-15px]">{userDetails.name}</p>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="font-poppins text-base font-medium text-left">
                  <p className="text-profile-item-gray">Last Name</p>
                  <p className=" mt-[-15px]">{userDetails.lastName}</p>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="font-poppins text-base font-medium text-left">
                  <p className="text-profile-item-gray">Email</p>
                  <p className=" mt-[-15px]">{userDetails.email}</p>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="font-poppins text-base font-medium text-left">
                  <p className="text-profile-item-gray">Phone No.</p>
                  <p className=" mt-[-15px]">{userDetails.phone}</p>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="font-poppins text-base font-medium text-left">
                  <p className="text-profile-item-gray">Address</p>
                  <p className=" mt-[-15px]">{userDetails.address}</p>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="font-poppins text-base font-medium text-left">
                  <p className="text-profile-item-gray">City</p>
                  <p className=" mt-[-15px]">{userDetails.city}</p>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="font-poppins text-base font-medium text-left">
                  <p className="text-profile-item-gray">State</p>
                  <p className=" mt-[-15px]">{userDetails.state}</p>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="font-poppins text-base font-medium text-left">
                  <p className="text-profile-item-gray">Country</p>
                  <p className=" mt-[-15px]">{userDetails.country}</p>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="font-poppins text-base font-medium text-left">
                  <p className="text-profile-item-gray">Company Name</p>
                  <p className=" mt-[-15px]">{userDetails.companyName}</p>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="font-poppins text-base font-medium text-left">
                  <p className="text-profile-item-gray">DOB</p>
                  {/* <p className=" mt-[-15px]">{userDetails.dob}</p> */}

                  <p className=" mt-[-15px]">{formatDate(userDetails.dob)}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Profile;
