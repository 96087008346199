import React, { useState, useEffect } from 'react';
import AdminSidebar from '../admin/AdminSidebar';
import iziToast from 'izitoast';
import { useAuth } from '../../AuthContext';

const FAQForm = () => {
    const { apipath } = useAuth();
    const [formData, setFormData] = useState({
        maintitle: '',
        statement: '',
        QA: [{ question: '', answer: '' }],
    });

    // Load data for an existing page if needed
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apipath}/faq/all`);
                if (response.ok) {
                    const result = await response.json();
                    setFormData(prevData => ({
                        ...prevData,
                        ...result,
                        QA: result.QA || [{ question: '', answer: '' }]
                    }));
                } else {
                    iziToast.error({ message: 'Failed to load data', position: 'topCenter' });
                }
            } catch (error) {
                iziToast.error({ message: `An error occurred: ${error.message}`, position: 'topCenter' });
            }
        };
        fetchData();
    }, []);

    const handleChange = (e, field) => {
        const { value } = e.target;
        setFormData(prevData => ({ ...prevData, [field]: value }));
    };

    const handleQAChange = (index, field, value) => {
        setFormData(prevData => {
            const updatedQA = [...prevData.QA];
            updatedQA[index] = { ...updatedQA[index], [field]: value };
            return { ...prevData, QA: updatedQA };
        });
    };

    const handleAddQA = () => {
        setFormData(prevData => ({
            ...prevData,
            QA: [...prevData.QA, { question: '', answer: '' }]
        }));
    };

    const handleRemoveQA = (index) => {
        setFormData(prevData => {
            const updatedQA = prevData.QA.filter((_, idx) => idx !== index);
            return { ...prevData, QA: updatedQA };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${apipath}/faq/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Network response was not ok: ${errorText}`);
            }

            const result = await response.json();

            if (result.success) {
                iziToast.success({ message: 'FAQ added/updated successfully', position: 'topCenter' });
            } else {
                iziToast.error({ message: `FAQ addition/update failed: ${result.error || 'Unknown error'}`, position: 'topCenter' });
            }
        } catch (error) {
            iziToast.error({ message: `An error occurred: ${error.message}`, position: 'topCenter' });
        }
    };

    return (
        <>
            <AdminSidebar />
            <div className="home">
                <div className="toggle-sidebar" style={{ display: 'none' }}>
                    <i className="bi bi-menu"></i>
                </div>
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Add or Update FAQ</h1>
                    </div>
                    <div className="container-fluid mt-3">
                        <form className="row login-form" onSubmit={handleSubmit}>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="maintitle" className="mb-2">Main Title</label>
                                <input
                                    type="text"
                                    id="maintitle"
                                    className="form-control"
                                    placeholder="Enter main title"
                                    value={formData.maintitle}
                                    onChange={(e) => handleChange(e, 'maintitle')}
                                />
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="statement" className="mb-2">Statement</label>
                                <input
                                    type="text"
                                    id="statement"
                                    className="form-control"
                                    placeholder="Enter statement"
                                    value={formData.statement}
                                    onChange={(e) => handleChange(e, 'statement')}
                                />
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label className="mb-2">Questions and Answers</label>
                                {formData.QA.map((item, index) => (
                                    <div key={index} className="card mb-3">
                                        <div className="card-body">
                                            <div className="mb-3">
                                                <label htmlFor={`question_${index}`} className="mb-2">Question</label>
                                                <input
                                                    type="text"
                                                    id={`question_${index}`}
                                                    className="form-control"
                                                    placeholder="Enter question"
                                                    value={item.question}
                                                    onChange={(e) => handleQAChange(index, 'question', e.target.value)}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor={`answer_${index}`} className="mb-2">Answer</label>
                                                <textarea
                                                    id={`answer_${index}`}
                                                    className="form-control"
                                                    placeholder="Enter answer"
                                                    value={item.answer}
                                                    onChange={(e) => handleQAChange(index, 'answer', e.target.value)}
                                                ></textarea>
                                            </div>
                                            <button
                                                type="button"
                                                className="btn btn-outline-danger"
                                                onClick={() => handleRemoveQA(index)}
                                            >
                                                Remove QA
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={handleAddQA}
                                >
                                    Add More QA
                                </button>
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FAQForm;
