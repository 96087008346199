import React, { useEffect, useState } from 'react';
import iziToast from 'izitoast';
import { useNavigate } from 'react-router-dom';
import AdminSidebar from './AdminSidebar'
import axios from 'axios';
import { useAuth } from '../../AuthContext';

const ProductAdd = () => {
  const [editorData, setEditorData] = useState('');
  const navigate = useNavigate();
  const { apipath, filepath, logout } = useAuth();
  const [formData, setFormData] = useState({
    productName: '',
    description: '',
    price: '',
    category: '',
    sectionId: '',
    a: '',
    b: '',
    t: '',
    wtkgm: '',
    dieAvailable: '',
    productPic: null,
  });

  const token = localStorage.getItem('token');

  const handleUploadButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'file') {
      const file = e.target.files[0];
      setFormData({
        ...formData,
        [name]: file,
        productPicPreview: URL.createObjectURL(file), // Set the preview URL
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const formdata = new FormData();
    formdata.append('productName', formData.productName);
    formdata.append('description', formData.description);
    formdata.append('price', formData.price);
    formdata.append('category', formData.category);
    formdata.append('sectionId', formData.sectionId);
    formdata.append('productPic', formData.productPic);
    formdata.append('a', formData.a);
    formdata.append('b', formData.b);
    formdata.append('t', formData.t);
    formdata.append('wtkgm', formData.wtkgm);
    formdata.append('dieAvailable', formData.dieAvailable);

    try {
      const response = await axios.post(`${apipath}/product/add`,
        formdata,
        {
          headers: {
            // 'Content-Type': 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 201) {
        iziToast.success({
          message: 'Product added successfully',
          position: 'topCenter',
        });
        navigate('/productsDetails');
      } else {
        iziToast.error({
          message: 'Product addition failed',
          position: 'topCenter',
        });
      }
    } catch (error) {
      console.error('Error adding product:', error);
      iziToast.error({
        message: 'An error occurred',
        position: 'topCenter',
      });
    }
  };


  return (
    <>
      <AdminSidebar />
      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Add Product Details</h1>
          </div>
          <div className="container-fluid mt-3">
            <form className="row login-form" onSubmit={handleRegister}>
              <div className="mb-3 col-lg-6">
                <label htmlFor="productName" className='mb-2'>Product Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter product Name"
                  name="productName"
                  value={formData.productName}
                  onChange={handleChange}
                  maxLength="100"
                  required
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="category" className='mb-2'>Category</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter product category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  maxLength="1000"
                  required
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="price" className='mb-2'>Price</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter product price"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  min="0"
                  required
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="sectionId" className='mb-2'>Section Id</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter product sectionId"
                  name="sectionId"
                  value={formData.sectionId}
                  onChange={handleChange}
                  maxLength="1000"
                  required
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="a" className='mb-2'>A</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter product A(numeric value)"
                  name="a"
                  value={formData.a}
                  onChange={handleChange}
                  maxLength="1000"
                  required
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="b" className='mb-2'>B</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter product B(numeric value)"
                  name="b"
                  value={formData.b}
                  onChange={handleChange}
                  maxLength="1000"
                  required
                />
              </div>

              <div className="mb-3 col-lg-6">
                <label htmlFor="t" className='mb-2'>T</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter product T(numeric value)"
                  name="t"
                  value={formData.t}
                  onChange={handleChange}
                  maxLength="1000"
                  required
                />
              </div>

              <div className="mb-3 col-lg-6">
                <label htmlFor="WT.KG/M" className='mb-2'>WT.KG/M</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter product WT.KG/M"
                  name="wtkgm"
                  value={formData.wtkgm}
                  onChange={handleChange}
                  maxLength="1000"
                  required
                />
              </div>
              <div className="mb-3 col-lg-12">
                <label htmlFor="description" className='mb-2'>Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Enter product description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  maxLength="1000"
                  required
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="dieAvailable" className='mb-2'>Die Available</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter product Die Available"
                  name="dieAvailable"
                  value={formData.dieAvailable}
                  onChange={handleChange}
                  maxLength="1000"
                  required
                />
              </div>
              <div className="change-profile pt-4 pb-4">
                <img
                  src={formData.productPic ? URL.createObjectURL(formData.productPic) : `${process.env.PUBLIC_URL}/imgs-alu/products-2.png`}
                  width={90}
                  height={90}
                  style={{ borderRadius: '35px' }}
                  alt="profile-img"
                  onClick={handleUploadButtonClick}
                />
                <h6>Add Product<br/>Image here:</h6>
                <input
                  hidden
                  type="file"
                  id="fileInput"
                  name="productPic"
                  onChange={handleChange}
                  style={{ display: 'none' }}
                />
              </div>
              <div className="submit-box pt-4 text-center">
                <button className="btn btn-primary" type="submit">Add Product</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductAdd;