import React, { useEffect, useState } from 'react';
import AdminSidebar from '../admin/AdminSidebar';
import iziToast from 'izitoast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

const AboutCmsPageEdit = () => {
  const { apipath, filepath } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    cardContent: '',
    heading1: '',
    image1: '',
    body1: [{ subheading1: '', content1: '', subheading2: '', content2: '' }],
    heading2: '',
    image2: '',
    body2: [{ subheading1: '', content1: '', subheading2: '', bulletins: [''] }],
  });
  const [images, setImages] = useState({ image1: null, image2: null });

  useEffect(() => {
    fetchCmsPageDetails();
  }, []);

  const fetchCmsPageDetails = async () => {
    try {
      const response = await axios.get(`${apipath}/about-cms/details`);
      const data = response.data.data;
      // console.log('Fetched data:', data);
      setFormData({
        ...data,
        body1: data.body1 || [{ subheading1: '', content1: '', subheading2: '', content2: '' }],
        body2: data.body2 || [{ subheading1: '', content1: '', subheading2: '', bulletins: [''] }],
      });
    } catch (error) {
      console.error('Error fetching CMS page details:', error);
    }
  };

  const handleChange = (e, field) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleBodyChange = (section, index, field, value) => {
    setFormData((prevData) => {
      const updatedSection = [...prevData[section]];
      updatedSection[index] = {
        ...updatedSection[index],
        [field]: value,
      };
      return { ...prevData, [section]: updatedSection };
    });
  };

  const handleBulletinChange = (index, subIndex, value) => {
    setFormData((prevData) => {
      const updatedBody2 = [...prevData.body2];
      updatedBody2[index].bulletins[subIndex] = value;
      return { ...prevData, body2: updatedBody2 };
    });
  };

  const handleAddBulletin = (index) => {
    setFormData((prevData) => {
      const updatedBody2 = [...prevData.body2];
      updatedBody2[index].bulletins.push('');
      return { ...prevData, body2: updatedBody2 };
    });
  };

  const handleFileChange = (e, imageType) => {
    setImages((prevImages) => ({
      ...prevImages,
      [imageType]: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('cardContent', formData.cardContent);
    data.append('heading1', formData.heading1);
    data.append('body1', JSON.stringify(formData.body1));
    data.append('heading2', formData.heading2);
    data.append('body2', JSON.stringify(formData.body2));

    if (images.image1) {
      data.append('image1', images.image1);
    }
    if (images.image2) {
      data.append('image2', images.image2);
    }

    try {
      const response = await fetch(`${apipath}/about-cms/update`, {
        method: 'PUT',
        body: data,
      });

      const result = await response.json();
      if (result.success) {
        iziToast.success({
          message: 'CMS Page updated successfully',
          position: 'topCenter',
        });
      } else {
        iziToast.error({
          message: 'CMS Page update failed',
          position: 'topCenter',
        });
      }
    } catch (error) {
      console.error('Error updating CMS page:', error);
      iziToast.error({
        message: 'An error occurred',
        position: 'topCenter',
      });
    }
  };

  return (
    <>
      <AdminSidebar />
      <div className="home">
        <div className="toggle-sidebar" style={{ display: 'none' }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header text-center">
            <h1>Edit CMS Page</h1>
          </div>
          <div className="container-fluid mt-3">
            <form className="row login-form" onSubmit={handleSubmit}>
              <div className="mb-3 col-lg-12">
                <label htmlFor="cardContent" className="mb-2">
                  Card Content
                </label>
                <textarea
                  id="cardContent"
                  className="form-control"
                  rows="5"
                  value={formData.cardContent}
                  onChange={(e) => handleChange(e, 'cardContent')}
                />
              </div>

              <div className="mb-3 col-lg-12">
                <h3>Body 1</h3>
                <label htmlFor="heading1" className="mb-2">
                  Heading 1
                </label>
                <input
                  type="text"
                  id="heading1"
                  className="form-control"
                  placeholder="Enter heading 1"
                  value={formData.heading1}
                  onChange={(e) => handleChange(e, 'heading1')}
                />
                <label htmlFor="image1" className="mt-2">
                  Image 1
                </label>
                <input
                  type="file"
                  id="image1"
                  className="form-control"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => handleFileChange(e, 'image1')}
                />
                {formData.image1 && (
                  <img
                    src={`${filepath}${formData.image1}`}
                    alt="Image 1"
                    style={{ width: '150px', height: 'auto', marginTop: '10px' }}
                  />
                )}
                {formData.body1.map((item, index) => (
                  <div key={index} className="mb-3">
                    <h4>Contents of Body1</h4>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Subheading 1"
                      value={item.subheading1}
                      onChange={(e) => handleBodyChange('body1', index, 'subheading1', e.target.value)}
                    />
                    <textarea
                      className="form-control"
                      rows={2}
                      placeholder="Content 1"
                      value={item.content1}
                      onChange={(e) => handleBodyChange('body1', index, 'content1', e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control mb-2 mt-2"
                      placeholder="Subheading 2"
                      value={item.subheading2}
                      onChange={(e) => handleBodyChange('body1', index, 'subheading2', e.target.value)}
                    />
                    <textarea
                      className="form-control"
                      rows={2}
                      placeholder="Content 2"
                      value={item.content2}
                      onChange={(e) => handleBodyChange('body1', index, 'content2', e.target.value)}
                    />
                  </div>
                ))}
              </div>

              <div className="mb-3 col-lg-12">
                <h3>Body 2</h3>
                <label htmlFor="heading2" className="mb-2">
                  Heading 2
                </label>
                <input
                  type="text"
                  id="heading2"
                  className="form-control"
                  placeholder="Enter heading 2"
                  value={formData.heading2}
                  onChange={(e) => handleChange(e, 'heading2')}
                />
                <label htmlFor="image2" className="mt-2">
                  Image 2
                </label>
                <input
                  type="file"
                  id="image2"
                  className="form-control"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => handleFileChange(e, 'image2')}
                />
                {formData.image2 && (
                  <img
                    src={`${filepath}${formData.image2}`}
                    alt="Image 2"
                    style={{ width: '150px', height: 'auto', marginTop: '10px' }}
                  />
                )}
                {formData.body2.map((item, index) => (
                  <div key={index} className="mb-3">
                    <h4>Contents of Body2</h4>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Subheading 1"
                      value={item.subheading1}
                      onChange={(e) => handleBodyChange('body2', index, 'subheading1', e.target.value)}
                    />
                    <textarea
                      className="form-control"
                      rows={2}
                      placeholder="Content 1"
                      value={item.content1}
                      onChange={(e) => handleBodyChange('body2', index, 'content1', e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control mb-2 mt-2"
                      placeholder="Subheading 2"
                      value={item.subheading2}
                      onChange={(e) => handleBodyChange('body2', index, 'subheading2', e.target.value)}
                    />
                    {item.bulletins.map((bulletin, subIndex) => (
                      <textarea
                        key={subIndex}
                        className="form-control mb-2"
                        rows={2}
                        placeholder="Bulletin"
                        value={bulletin}
                        onChange={(e) => handleBulletinChange(index, subIndex, e.target.value)}
                      />
                    ))}
                    <button
                      type="button"
                      className="btn btn-primary mt-2"
                      onClick={() => handleAddBulletin(index)}
                    >
                      Add Bulletin
                    </button>
                  </div>
                ))}
              </div>

              <div className="col-lg-12 text-center">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCmsPageEdit;
