import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import AdminSidebar from './AdminSidebar';
import iziToast from 'izitoast';
import { format } from 'date-fns';
import $ from 'jquery';
import { useAuth } from "../../AuthContext";

const ShippingDetail = () => {
    const [pendingOrders, setPendingOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dataTableRef = useRef();
    const searchInputRef = useRef();
    const { apipath } = useAuth();

    useEffect(() => {
        fetchPendingOrders();
    }, []);

    useEffect(() => {
        if (pendingOrders.length > 0) {
            $(dataTableRef.current).DataTable();
        }
    }, [pendingOrders]);

    useEffect(() => {
        if (pendingOrders.length > 0) {
            const dataTable = $(dataTableRef.current).DataTable();
            $(searchInputRef.current).on('keyup', function () {
                dataTable.search(this.value).draw();
            });
        }
    }, [pendingOrders]);

    const fetchPendingOrders = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            const response = await axios.get(`${apipath}/order/orderdetails`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const orders = response.data || [];
            if (!Array.isArray(orders)) {
                console.error('Expected orders to be an array but got:', orders);
                throw new Error('Unexpected response structure');
            }

            const filteredOrders = orders.filter(order => order.status !== "Completed");
            setPendingOrders(filteredOrders);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching pending orders:', error);
            setIsLoading(false);
        }
    };

    const handleToggleStatus = async (id, field, value) => {
        try {
            setIsLoading(true);

            // Construct update data based on the field type
            let updateData;
            if (field === 'orderUpdate') {
                updateData = { orderUpdate: [{ date: new Date(), paymentplaced: value }] };
            } else if (field === 'dispatchUpdate') {
                updateData = { dispatchUpdate: [{ date: new Date(), dispatchstatus: value }] };
            } else if (field === 'deliverUpdate') {
                updateData = { deliverUpdate: [{ date: new Date(), deliverstatus: value }] };
            }

            // Handle the case where value is false (unchecking the checkbox)
            if (!value) {
                updateData = { [field]: [{ date: null, [field === 'dispatchUpdate' ? 'dispatchstatus' : field]: false }] };
            }

            const token = localStorage.getItem('token');
            const response = await axios.put(`${apipath}/order/orderdetails/${id}`, updateData, {
                headers: { Authorization: `Bearer ${token}` }
            });
            await fetchPendingOrders();
            setIsLoading(false);

            if (response.status === 200) {
                iziToast.success({
                    message: "Order update successful",
                    position: "topCenter",
                });
            } else {
                iziToast.error({
                    message: "Order update failed",
                    position: "topCenter",
                });
            }
        } catch (error) {
            setIsLoading(false);
            iziToast.error({
                message: "An error occurred",
                position: "topCenter",
            });
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Invalid date';
        }
        return format(date, 'dd MMM yyyy');
    };

    const getPaymentStatus = (updates) => {
        const lastUpdate = updates[updates.length - 1];
        return lastUpdate ? lastUpdate.paymentplaced : false;
    };

    const getDispatchStatus = (updates) => {
        const lastUpdate = updates[updates.length - 1];
        return lastUpdate ? lastUpdate.dispatchstatus : false;
    };

    const getDeliverStatus = (updates) => {
        const lastUpdate = updates[updates.length - 1];
        return lastUpdate ? lastUpdate.deliverstatus : false;
    };

    return (
        <>
            <AdminSidebar />
            <div className="home">
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className="bi bi-menu"></i>
                </div>
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Shipping Management</h1>
                    </div>
                    <div className="container-fluid">
                        <div className="row foot-tablerow">
                            <div className="col-lg-11 maintable-column mx-auto">
                                <div className="container mt-4 overflow-auto">
                                    <div className="d-flex justify-content-between mb-3">
                                        <div></div>
                                        <div className="d-flex align-items-center">
                                            <label className="me-2">Search:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search orders..."
                                                ref={searchInputRef}
                                                style={{ width: '200px' }}
                                            />
                                        </div>
                                    </div>
                                    <table id="datatable" className="table" ref={dataTableRef}>
                                        <thead>
                                            <tr className="tr1">
                                                <th>S.No.</th>
                                                <th>Order Id</th>
                                                <th>Order Date</th>
                                                <th>Payment Status</th>
                                                <th>Confirmed</th>
                                                <th>Dispatched</th>
                                                <th>Delivered</th>
                                                <th>Order Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pendingOrders.length > 0 ? (
                                                pendingOrders.map((order, index) => (
                                                    <tr key={order._id}>
                                                        <td className='text-center'>{index + 1}</td>
                                                        <td className="ellipsis"><abbr
                                                            className="text-decoration-none me-4"
                                                            title={order._id}
                                                        >
                                                            {order._id}
                                                        </abbr></td>
                                                        <td>{formatDate(order.orderUpdate[0]?.paymentDate)}</td>
                                                        <td className="success">
                                                            <span className={`success ${getPaymentStatus(order.orderUpdate) ? 'paid' : 'unpaid'}`}>{getPaymentStatus(order.orderUpdate) ? 'Paid' : 'Unpaid'}</span>
                                                        </td>
                                                        <td className="text-center">
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={getPaymentStatus(order.orderUpdate)}
                                                                    onChange={() => handleToggleStatus(order._id, 'orderUpdate', !getPaymentStatus(order.orderUpdate))}
                                                                />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                        <td className="text-center">
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={getDispatchStatus(order.dispatchUpdate)}
                                                                    onChange={() => handleToggleStatus(order._id, 'dispatchUpdate', !getDispatchStatus(order.dispatchUpdate))}
                                                                />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                        <td className="text-center">
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={getDeliverStatus(order.deliverUpdate)}
                                                                    onChange={() => handleToggleStatus(order._id, 'deliverUpdate', !getDeliverStatus(order.deliverUpdate))}
                                                                />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                        <td className="pending"><span>{order.status}</span></td>
                                                        <td>
                                                            <i className="fa-solid fa-trash"
                                                            // onClick={() => handleDelete(order._id)}
                                                            ></i>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="9" className="text-center">No data available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShippingDetail;
