import React, { useEffect, useRef, useState } from 'react'
import AdminSidebar from './AdminSidebar'
import axios from 'axios';
import $ from 'jquery';
// import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import iziToast from "izitoast";
import { useAuth } from '../../AuthContext';

const CustomerDetails = () => {

  const [users, setUsers] = useState([]);
  const dataTableRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const { apipath } = useAuth();

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (!isLoading && users.length > 0) {
      $(dataTableRef.current).DataTable();
    }
  }, [isLoading, users]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${apipath}/users/details`);
      setUsers(response.data.user);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const handleShow = (user) => {
    if (user.role === 'admin') {
      iziToast.error({
        message: "You cannot delete the Admin.",
        position: "topCenter"
      });
    } else {
      setUserToDelete(user._id);
      setShow(true);
    }
  };

  const handleClose = () => {
    setUserToDelete(null);
    setShow(false);
  };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      await axios.delete(`${apipath}/users/details/${id}`);
      setUsers(users.filter(user => user._id !== userToDelete));
      await fetchUsers();
      handleClose();
    } catch (error) {
      console.error('Error deleting course:', error);
    }
  };

  return (
    <>
      <AdminSidebar />
      <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Customer Management</h1>
          </div>

          <div className="container-fluid">
            <div class="input-group search-group">
              <div class="input-group-prepend search-prepend">
                <span class="input-group-text search-text" id="basic-addon1">
                  <i className="bi bi-search"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control search-control"
                placeholder="Search by name"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column">
                <div className="container mt-4">
                  <table id="datatable" className="table">
                    <thead>
                      <tr className="tr1">
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Mobile Number</th>
                        <th>Email</th>
                        <th>Company Name</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user, index) => (
                        <tr key={user._id}>
                          <td>{index + 1}</td>
                          <td>{user.name}</td>
                          <td>{user.phone}</td>
                          <td>{user.email}</td>
                          <td>{user.companyName}</td>
                          <td className="success"><i class="fa-solid fa-check"></i> Active</td>
                          {/* <td className="pending"><i class="fa-solid fa-spinner"></i> Inactive</td> */}
                          <td>{/* <i className="fa-regular fa-pen-to-square"></i> &nbsp; */}
                            <Modal show={show} onHide={handleClose} centered>
                              <Modal.Header closeButton>
                                <Modal.Title>Delete User</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="modal-content1">
                                  <p>Are you sure you want to delete this user?</p>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button className="modal-footer-btn" onClick={handleClose}>
                                  Cancel
                                </button>
                                <button className="modal-footer-btn" onClick={() => handleDelete(user._id)}>
                                  Delete
                                </button>
                              </Modal.Footer>
                            </Modal>
                            <i className="fa-solid fa-trash" onClick={() => handleShow(user)}></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomerDetails