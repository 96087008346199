import React, { useState } from "react";
import { Link ,useNavigate} from "react-router-dom";
import Navbar from "../pages/Navbar";
import Footer from "../pages/Footer";

import iziToast from "izitoast";
import { useAuth } from "../../AuthContext";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { apipath } = useAuth();

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        apipath+"/users/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData)
        }
      );

      const result = await response.json();
      localStorage.setItem("token", result.token);
      // console.log(result);
      
      if (result.success === true) {
        iziToast.success({
          message: "Login successful",
          position: "topCenter"
        });
        if(result.user.role === 'admin'){
          localStorage.setItem('admintoken', result.token);
          localStorage.setItem('adminname', result.user.name);
          localStorage.setItem('adminemail', result.user.email);
          localStorage.setItem('adminId', result.user._id);
          navigate("/adminDashboard");
        }
        else{
          localStorage.setItem('name', result.user.name);
          localStorage.setItem('email', result.user.email);
          localStorage.setItem('userId',result.user._id);
          localStorage.setItem('token', result.token);

          const redirectTo = localStorage.getItem('redirectAfterLogin') || '/';
          localStorage.removeItem('redirectAfterLogin');
          navigate(redirectTo);

        }
      } else {
        iziToast.error({
          message: "Login failed : "+result.error,
          position: "topCenter"
        });
        console.error("Login failed:", result.error);
      }
    } catch (error) {
      iziToast.error({
        message: "Error during login",
        position: "topCenter"
      });
      console.error("Error during login:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <>
      <Navbar />

      <div className="container my-5 p-2 bg-login-blue shadow-custom-login rounded-2xl flex flex-wrap">
        <div className="w-full lg:w-6/12">
          <img src="imgs-alu/login.png" alt="" className="w-full" />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col justify-center items-center">
          <div className="flex items-center">
            <div>
              <img src="imgs-alu/waving-hand.png" alt="" />
            </div>
            <h1 className="font-montserrat font-semibold text-[36px] text-m-blue ml-3">
              Welcome Back
            </h1>
          </div>
          <p className="font-poppins font-medium text-lg text-dark-gray">
            Please Login to your account
          </p>
          <form action="" className="w-full" onSubmit={handleSubmit}>
            <div className="w-full lg:w-8/12 mx-auto mt-3">
              <label
                htmlFor=""
                className="block font-poppins font-medium text-base mb-1"
              >
                Email Id
              </label>
              <input
                type="email"
                className="w-full p-2 bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3" value={formData.email}
                    placeholder="Enter your email"
                name="email"
                    onChange={handleChange}
                    required 
              />
            </div>
            <div className="w-full lg:w-8/12 mx-auto my-3">
              <label
                htmlFor=""
                className="block font-poppins font-medium text-base mb-1"
              >
                Password
              </label>
              <div className="flex w-full p-2 bg-login-input-blue rounded-[10px]">
                <input
                  type={showPassword ? "text" : "password"}
                  className="w-full bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3"
                  placeholder="Enter your password"
                  name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required 

                />
                <span>
                  <i
                    className={`cursor-pointer ${
                      showPassword ? "bi bi-eye-slash" : "bi bi-eye"
                    }`}
                    onClick={handleVisibility}
                  ></i>
                </span>
              </div>
              <p className="text-right font-poppins font-normal text-base  mt-1">
                <Link to="/forgot" className="no-underline text-m-blue">
                  Forgot Password ?
                </Link>
              </p>
            </div>

            <div className="w-full lg:w-8/12 mx-auto pt-3">
                <button className="w-full py-[10px] bg-m-blue text-white rounded-[10px] text-center font-Poppins text-lg font-medium">
                  Login
                </button>
            </div>
          </form>
          <p className="text-black text-center font-poppins text-base font-medium leading-8">
            Don’t have an account ?{" "}
            <Link to="/register" className="no-underline">
              <span className="text-m-blue">Register</span>
            </Link>
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Login;