import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from 'react-router-dom';

const Thanks = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);


  return (
    <>
    <Navbar />

    <div className="container">
      <div className="row p-5">
        <div className="col-lg-8 mx-auto thanks">
            <div className="thanks-img">
                <img src="imgs-alu/congrats.png" alt="" />
                
            </div>
            <h1>Thank you for Ordering !!</h1>
            <p>Your Order will be delivered shortly</p>
            <div className="thanks-button">
                <Link to="/"><button type="button">Back to Home</button></Link>
              </div>
        </div>
      </div>
    </div>
    
    <Footer />
    </>
  )
}

export default Thanks