// import React from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";

// const Extrusion = () => {
//   return (
//     <>
//       <Navbar />

//       <Link to="/" className="float">
//         <img src="imgs-alu/whtsp.png" alt="" />
//       </Link>

//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//             Extrusion
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home{" "}
//             <i className="bi bi-chevron-double-right text-light-yellow"></i>
//             Extrusion
//           </h4>
//         </div>
//       </div>

//       <div className="container py-5">
//         <img src="imgs-alu/extrusion.png" alt="" className="w-full" />
//       </div>

//       <div className="container-fluid py-5 my-3 bg-yellow-2">
//         <div className="main-heading">
//           <h1>
//             E<span>xtrusio</span>n
//           </h1>
//         </div>
//         <div className="container">
//           <div className="w-full lg:w-9/12 mx-auto">
//             <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
//               Narayan Aluminium Alloys Pvt. Ltd offers a superior Extrusion
//               Process with the promise of providing the highest standards of
//               manufacturing material. We deal with a diverse range of businesses
//               that includes transportation, construction, electrical and
//               telecommunications, and the solar power industry, among others.
//             </p>
//             <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
//               The extrusion process employs the tensile strength of the material
//               to pull it through a die. Metal bars, wires, and tubes are created
//               using this technique. Extrusion is a multi-step procedure, as
//               opposed to a one-step approach
//             </p>
//           </div>
//         </div>
//       </div>

//       <div className="container py-4">
//         <div className="flex flex-wrap py-3">
//           <div className="w-full md:w-6/12">
//             <div className="p-3">
//               <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
//                 Our Extrusion Method :
//               </h2>

//               <p className="pt-1 font-nunito font-normal text-lg text-dark-gray leading-8">
//                 The extrusion procedure is simple and clear. Extrusion molding
//                 is seen in the shape toothpaste takes as it emerges from your
//                 squeeze. Icing bags are yet another way how icing comes out
//               </p>

//               <p className="font-nunito font-normal text-lg text-dark-gray leading-8">
//                 During extrusion, the base material is pressed into a pre-shaped
//                 die to produce a variety of useful things such as Architectural
//                 Building Profiles, Architectural Hardware, Industrial
//                 Applications, and many more.
//               </p>
//               <p className="font-nunito font-normal text-lg text-dark-gray leading-8">
//                 Material is pushed through a die with a particular
//                 cross-section. The shape of a die is important in influencing
//                 how well the material will transform into the required shape. We
//                 supply you with an Extrudate, which is the full extrusion
//                 product. To get the extrusion ratio, just divide the area of the
//                 starting part by the size of the last section.
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12">
//             <div className="p-3">
//               <img className="w-full" src="imgs-alu/extrusion2.png" alt="" />
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container pb-4">
//         <div className="flex flex-wrap">
//           <div className="w-full lg:w-1/2 p-2">
//             <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
//               <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
//                 Industries that rely on our high-quality extrusion products:
//               </h2>
//               <div className="list-none">
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Electrical Sector
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Construction/Architecture
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Electronics/Appliances
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Transportation/automotive sector
//                 </li>
//               </div>
//             </div>
//           </div>
//           <div className="w-full lg:w-1/2 p-2">
//             <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
//               <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
//                 We provide verities of industrial extrusion products, including
//                 :
//               </h2>
//               <div className="list-none">
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Electrical Sector
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Construction/Architecture
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Electronics/Appliances
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Transportation/automotive sector
//                 </li>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container py-4">
//         <p className="font-nunito font-normal text-dark-gray text-lg ">
//           For more information about Narayan Aluminium Alloys Pvt. Ltd custom
//           extruded aluminum profiles and metal fabrication services, please send
//           us your queries.
//         </p>
//       </div>

//       <div className="container enquiry">
//         <div className="main-heading">
//           <h1>
//             E<span>nquire No</span>w
//           </h1>
//         </div>
//         <div className="my-4 lg:p-5 p-3 w-full lg:w-8/12 bg-enquiry rounded-[10px] mx-auto">
//           <form action="" className="flex flex-wrap">
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Name
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Name"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Phone No.
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Phone no"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Email
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Email"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Address
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Address"
//                 />
//               </div>
//             </div>
//             <div className="w-full p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Message
//                 </label>
//                 <textarea
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Message"
//                 >
//                   {" "}
//                 </textarea>
//               </div>
//             </div>
//             <div className="p-2">
//               <button className="rounded-[10px] bg-enquiry-button font-poppins font-medium text-white text-lg py-1 px-4">
//                 Send
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Extrusion;


import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useSearchParams } from "react-router-dom";
import EnquiryForm from "./EnquiryForm";
import { useAuth } from "../../AuthContext";
import axios from 'axios';

const Extrusion = () => {
  const { apipath, filepath } = useAuth();
  const [extrusionData, setExtrusionData] = useState(null);
  const [searchParams] = useSearchParams();
  const pageType = searchParams.get("pageType") || "Default";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/manufacturingcms/content/${pageType}`);
        // Log the data portion of the response
        //  console.log('Response Data:', response.data);
        setExtrusionData(response.data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, [pageType]);

  if (!extrusionData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Extrusion
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            Extrusion
          </h4>
        </div>
      </div>

      <div className="container py-5">
        <img src={filepath + extrusionData.bannerImage} alt="" className="w-full" />
      </div>

      <div className="container-fluid py-5 my-3 bg-yellow-2">
        <div className="main-heading">
          <h1>
            {extrusionData.maintitle}
          </h1>
        </div>
        <div className="container">
          <div className="w-full lg:w-9/12 mx-auto">
            <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
            {extrusionData.headContent}
            </p>
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="flex flex-wrap py-3">
          <div className="w-full md:w-6/12">
            <div className="p-3" >
              <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
                {extrusionData.body1title}
              </h2>
              {extrusionData.body1content.map((item, index) => (
                <p className="pt-1 font-nunito font-normal text-lg text-dark-gray leading-8" key={index}>
                  {item.content}
                </p>
              ))}
            </div>
          </div>
          <div className="w-full md:w-6/12">
            <div className="p-3">
              <img className="w-full" src={filepath + extrusionData.sideImage} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-4">
        <div className="flex flex-wrap">
        {extrusionData.body2card.map((item, index) => (
          <div className="w-full lg:w-1/2 p-2" key={index}>
              <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
                <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
                  {item.cardTitle}
                </h2>
                <div className="list-none" >
                  {item.bulletins.map((bulletin, index) => (
                    <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1" key={index}>
                      <div>
                        <img
                          src="imgs-alu/material-symbols_point-scan.png"
                          alt=""
                          className="mr-2"
                        />
                      </div>{" "}
                      {bulletin}
                    </li>
                  ))}
                </div>
              </div>
          </div>
          ))}
        </div>
      </div>

      <div className="container py-4">
        <p className="font-nunito font-normal text-dark-gray text-lg ">
          {extrusionData.bottomstatement}
        </p>
      </div>

      <EnquiryForm/>

      <Footer />
    </>
  );
};

export default Extrusion;