// src/components/ExampleCarouselImage.js

import React from 'react';
import { Link } from "react-router-dom";

const ExampleCarouselImage = ({ text, imgUrl }) => {
  return (
    <div className="container-fluid banner">
        <div className="row banner-row">
          <div className="col-lg-6 banner-left">
            {/* <div className="banner-icons">
              <img src="imgs-alu/logos_facebook.png" alt="" />
              <img src="imgs-alu/skill-icons_instagram.png" alt="" />
              <img src="imgs-alu/logos_twitter.png" alt="" />
              <img src="imgs-alu/devicon_linkedin.png" alt="" />
            </div> */}
            <h1>{text}</h1>
            <div className="banner-button">
            <Link to="/product-list">
              <button>Check Our Products</button>
            </Link>
            </div>
          </div>
          <div className="col-lg-6 banner-img">
            <img src={imgUrl} alt="" />
          </div>
        </div>
      </div>
  );
};

export default ExampleCarouselImage;
