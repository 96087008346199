// import React, { useEffect } from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";

// const Coloring = () => {
//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "auto",
//     });
//   }, []);

//   return (
//     <>
//       <Navbar />

//       <Link to="/" className="float">
//         <img src="imgs-alu/whtsp.png" alt="" />
//       </Link>

//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//             Colouring
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home{" "}
//             <i className="bi bi-chevron-double-right text-light-yellow"></i>
//             Colouring
//           </h4>
//         </div>
//       </div>

//       <div className="container py-5">
//         <img src="imgs-alu/powder.png" alt="" className="w-full" />
//       </div>

//       <div className="container-fluid py-5 my-3 bg-yellow-2">
//         <div className="main-heading">
//           <h1>
//             C<span>olourin</span>g
//           </h1>
//         </div>
//         <div className="container">
//           <div className="w-full lg:w-9/12 mx-auto">
//             <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
//               Coloring your manufacturing goods- Organic decorative and
//               protective coating applied on aluminum coils. Narayan Aluminium
//               Alloys Pvt. Ltd provides color coating services that are corrosion
//               resistant in corrosive conditions and retain base strength and
//               integrity. Our continuous and highly automated method makes
//               painting aluminum coils beneficial and cost-effective. We meet our
//               customers’ highest functional and artistic expectations.
//             </p>
//             <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
//               The color coating is applied on aluminum to give lifespan and
//               levels of protection. It protects Aluminum from environmental
//               damage by wrapping it in a layer.
//             </p>
//           </div>
//         </div>
//       </div>

//       <div className="container py-4">
//         <div className="flex flex-wrap py-3">
//           <div className="w-full md:w-6/12">
//             <div className="p-3">
//               <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
//                 Coloring Method :
//               </h2>

//               <p className="pt-1 font-nunito font-normal text-lg text-dark-gray leading-8">
//                 <b>
//                   The color coating method is well-known for its several
//                   advantages, including efficiency, and environmental
//                   friendliness.
//                 </b>{" "}
//                 <br />
//                 According to EN 10169:2010, our color-coating aluminum is a
//                 continuous process that involves cleaning.
//                 <br /> Following a chemical treatment of the metal surface or
//                 coating powders that will be dried and laminated with permanent
//                 plastic sheets, one or two coats of paint will be applied.{" "}
//                 <br /> Aluminum substrates are colored to create low-cost,
//                 environmentally friendly goods. <br /> The most common aluminum
//                 substrates are given below :
//               </p>
//               <ul className="list-disc">
//                 <li className="font-nunito font-normal text-lg text-dark-gray leading-8">
//                   Aluminum, cold-rolled.
//                 </li>
//                 <li className="font-nunito font-normal text-lg text-dark-gray leading-8">
//                   Aluminum that has been hot-dipped galvanized.
//                 </li>
//                 <li className="font-nunito font-normal text-lg text-dark-gray leading-8">
//                   Aluminum electro-galvanized.
//                 </li>
//                 <li className="font-nunito font-normal text-lg text-dark-gray leading-8">
//                   Galvalume which is composed of 55% aluminum, 43% zinc, and
//                   1.5% silicon, is also known as Zincalume.
//                 </li>
//                 <li className="font-nunito font-normal text-lg text-dark-gray leading-8">
//                   Galfan is made up of 95% zinc and 5% aluminum.
//                 </li>
//               </ul>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12 flex items-center">
//             <div className="p-3">
//               <img className="w-full" src="imgs-alu/color.png" alt="" />
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container pb-4">
//         <div className="flex flex-wrap">
//           <div className="w-full lg:w-1/2 p-2">
//             <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
//               <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
//                 Coil coating procedure:
//               </h2>

//               <div className="list-disc font-nunito font-normal text-base text-dark-gray leading-6">
//                 <li className="mb-1">
//                   Strips of aluminum that are mechanically stitched and provided
//                   in coils.
//                 </li>
//                 <li className="mb-1">
//                   Cleaning and chemical cleaning is used to prepare the metal
//                   surface.
//                 </li>
//                 <li className="mb-1">
//                   Applying a primer to one or both surfaces, then drying and
//                   cooling.
//                 </li>
//                 <li className="mb-1">
//                   The coating is on both sides of the finish, then cooling and
//                   drying.
//                 </li>
//                 <li className="mb-1">
//                   After quality checking, coils are rolled.
//                 </li>
//               </div>
//             </div>
//           </div>
//           <div className="w-full lg:w-1/2 p-2">
//             <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
//               <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
//                 Process of coil coating:
//               </h2>

//               <div className="list-disc font-nunito font-normal text-base text-dark-gray leading-6 ">
//                 <li className="mb-1">
//                   Aluminum strips that are mechanically stitched and delivered
//                   in coils.
//                 </li>
//                 <li className="mb-1">
//                   Preparation of the metal surface by cleaning and chemical
//                   cleaning.
//                 </li>
//                 <li className=" mb-1">
//                   Applying a primer on one or both sides, after which dried and
//                   cooled
//                 </li>
//                 <li className="mb-1">
//                   A coating for either side of the finish, followed by cooling
//                   and drying.
//                 </li>
//                 <li className=" mb-1">
//                   Rolling into coils after quality control.
//                 </li>
//               </div>
//             </div>
//           </div>

//           <div className="w-full lg:w-1/2 p-2 mt-3">
//             <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
//               <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
//                 Profitable Advantages for Your Company
//               </h2>
//               <p className="font-nunito font-normal text-base text-dark-gray leading-4">
//                 Aside from its exceptional machinability, aluminum has several
//                 properties that make it appropriate for machining:
//               </p>
//               <div className="list-none">
//                 <li className="font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div className="flex items-center">
//                     <div>
//                       <img
//                         src="imgs-alu/material-symbols_point-scan.png"
//                         alt=""
//                         className="mr-2"
//                       />
//                     </div>{" "}
//                     <p className="font-nunito font-normal text-base text-dark-gray leading-6 ml-5">
//                       <b className="font-exo font-bold text-lg text-dark-gray">
//                         Beneficial : 
//                       </b>
//                       Color coating and galvanized metal are both effective
//                       corrosion-control technologies when combined.
//                     </p>
//                   </div>
//                 </li>
//                 <li className="font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div className="flex items-center">
//                     <div>
//                       <img
//                         src="imgs-alu/material-symbols_point-scan.png"
//                         alt=""
//                         className="mr-2"
//                       />
//                     </div>{" "}
//                     <p className="font-nunito font-normal text-base text-dark-gray leading-6 ml-5">
//                       <b className="font-exo font-bold text-lg text-dark-gray">
//                         Durability : 
//                       </b>
//                       Color coatings enhance the life of galvanized aluminum by
//                       reducing zinc consumption and enhancing catholic and
//                       barrier stability.
//                     </p>
//                   </div>
//                 </li>
//                 <li className="font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div className="flex items-center">
//                     <div>
//                       <img
//                         src="imgs-alu/material-symbols_point-scan.png"
//                         alt=""
//                         className="mr-2"
//                       />
//                     </div>{" "}
//                     <p className="font-nunito font-normal text-base text-dark-gray leading-6 ml-5">
//                       <b className="font-exo font-bold text-lg text-dark-gray">
//                         Prevent Wastage : 
//                       </b>
//                       If a color coating becomes weathered or damaged, zinc can
//                       work as a catholic and barrier protection, preventing rust
//                       after the paint peels off.
//                     </p>
//                   </div>
//                 </li>
//                 <li className="font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div className="flex items-center">
//                     <div>
//                       <img
//                         src="imgs-alu/material-symbols_point-scan.png"
//                         alt=""
//                         className="mr-2"
//                       />
//                     </div>{" "}
//                     <p className="font-nunito font-normal text-base text-dark-gray leading-6 ml-5">
//                       <b className="font-exo font-bold text-lg text-dark-gray">
//                         Corrosion Protection : 
//                       </b>
//                       A duplex coating provides corrosion protection 1.5 to 2.5
//                       times longer than the sum of the life spans of the zinc
//                       and color coatings
//                     </p>
//                   </div>
//                 </li>
//               </div>
//             </div>
//           </div>
//           <div className="w-full lg:w-1/2 p-2 mt-3">
//             <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
//               <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
//                 Color-Coated Aluminum Applications:
//               </h2>
//               <p className="font-nunito font-normal text-base text-dark-gray leading-2">
//                 The following applications make use of color-coated aluminum:
//               </p>
//               <div className="list-none">
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Roofing and wall covering for industrial and residential
//                   structures, as well as cold storage and warehouses.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Walls and fences.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Refrigerators, deep freezers, washing machines, and other
//                   white goods are examples of white goods.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Control panels for electronic devices.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Casings and housings.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Industrial racking and furniture.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Luxury coaches, train coaches, bus bodies, and so forth.
//                 </li>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container py-4">
//         <p className="font-nunito font-normal text-dark-gray text-lg ">
//           We at Narayan Aluminium Alloys Pvt. Ltd provide the ideal solution for
//           items that demand a high strength-to-weight ratio for your Machining
//           requirements. We recognize that the production material is critical in
//           the planning of a machining job.
//         </p>
//         <p className="font-nunito font-normal text-dark-gray text-lg ">
//           Get in touch with us for further information.
//         </p>
//       </div>

//       <div className="container enquiry">
//         <div className="main-heading">
//           <h1>
//             E<span>nquire No</span>w
//           </h1>
//         </div>
//         <div className="my-4 lg:p-5 p-3 w-full lg:w-8/12 bg-enquiry rounded-[10px] mx-auto">
//           <form action="" className="flex flex-wrap">
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Name
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Name"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Phone No.
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Phone no"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Email
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Email"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Address
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Address"
//                 />
//               </div>
//             </div>
//             <div className="w-full p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Message
//                 </label>
//                 <textarea
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Message"
//                 >
//                   {" "}
//                 </textarea>
//               </div>
//             </div>
//             <div className="p-2">
//               <button className="rounded-[10px] bg-enquiry-button font-poppins font-medium text-white text-lg py-1 px-4">
//                 Send
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Coloring;


import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useSearchParams } from "react-router-dom";
import EnquiryForm from "./EnquiryForm";
import { useAuth } from "../../AuthContext";
import axios from 'axios';

const Coloring = () => {
  const { apipath, filepath } = useAuth();
  const [coloringData, setColoringData] = useState(null);
  const [searchParams] = useSearchParams();
  const pageType = searchParams.get("pageType") || "Default";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/manufacturingcms/content/${pageType}`);
        // Log the data portion of the response
        //  console.log('Response Data:', response.data);
        setColoringData(response.data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, [pageType]);

  if (!coloringData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Colouring
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            Colouring
          </h4>
        </div>
      </div>

      <div className="container py-5">
        <img src={filepath + coloringData.bannerImage} alt="" className="w-full" />
      </div>

      <div className="container-fluid py-5 my-3 bg-yellow-2">
        <div className="main-heading">
          <h1>
          {coloringData.maintitle}
          </h1>
        </div>
        <div className="container">
          <div className="w-full lg:w-9/12 mx-auto">
            <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
            {coloringData.headContent}
            </p>
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="flex flex-wrap py-3">
          <div className="w-full md:w-6/12">
            <div className="p-3">
              <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
              {coloringData.body1title}
              </h2>

              <p className="pt-1 font-nunito font-normal text-lg text-dark-gray leading-8">
                <b>
                {coloringData.body1content[0].content}
                </b>{" "}
                <br />
                {coloringData.body1content[1].content} <br /> {coloringData.body1content[2].content}
              </p>
              <ul className="list-disc">
                <li className="font-nunito font-normal text-lg text-dark-gray leading-8">
                {coloringData.body1content[3].content}
                </li>
                <li className="font-nunito font-normal text-lg text-dark-gray leading-8">
                {coloringData.body1content[4].content}
                </li>
                <li className="font-nunito font-normal text-lg text-dark-gray leading-8">
                {coloringData.body1content[5].content}
                </li>
                <li className="font-nunito font-normal text-lg text-dark-gray leading-8">
                {coloringData.body1content[6].content}
                </li>
                <li className="font-nunito font-normal text-lg text-dark-gray leading-8">
                {coloringData.body1content[7].content}
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full md:w-6/12 flex items-center">
            <div className="p-3">
              <img className="w-full" src={filepath + coloringData.sideImage} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-4">
        <div className="flex flex-wrap">
        {coloringData.body2card.slice(0, 2).map((item, index) => (
          <div className="w-full lg:w-1/2 p-2" key={index}>
            <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
              <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
              {item.cardTitle}
              </h2>

              <div className="list-disc font-nunito font-normal text-base text-dark-gray leading-6">
              {item.bulletins.map((bulletin, index) => (
                <li className="mb-1" key={index}>
                  {bulletin}
                </li>
              ))}
              </div>
            </div>
          </div>
        ))}
        {coloringData.body2card.slice(2, ).map((item, index) => (
          <div className="w-full lg:w-1/2 p-2 mt-3"  key={index}>
            <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
              <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
              {item.cardTitle}
              </h2>
              <p className="font-nunito font-normal text-base text-dark-gray leading-4">
              {item.cardContent || ""}
              </p>
              <div className="list-none">
              {item.bulletins.map((bulletin, index) => (
                <li className="font-exo font-normal text-lg text-dark-gray mb-1" key={index}>
                  <div className="flex items-center">
                    <div>
                      <img
                        src="imgs-alu/material-symbols_point-scan.png"
                        alt=""
                        className="mr-2"
                      />
                    </div>{" "}
                    <p className="font-nunito font-normal text-base text-dark-gray leading-6 ml-5">
                      {bulletin}
                    </p>
                  </div>
                </li>
              ))}
              </div>
            </div>
          </div>
        ))}
        </div>
      </div>

      <div className="container py-4">
        <p className="font-nunito font-normal text-dark-gray text-lg ">
          {coloringData.bottomstatement}
        </p>
      </div>

      <EnquiryForm />

      <Footer />
    </>
  );
};

export default Coloring;
