import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "../../AuthContext";

const EnquiryForm = () => {
  const { apipath } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    phoneNo: "",
    email: "",
    address: "",
    message: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apipath}/enquireNow/add`, formData);
      if (response.data.success) {
        setSuccess("Enquiry submitted successfully!");
        setFormData({
          name: "",
          phoneNo: "",
          email: "",
          address: "",
          message: "",
        });
        setError("");
      }
    } catch (err) {
      setError("Failed to submit enquiry. Please try again.");
      setSuccess("");
    }
  };

  return (
    <div className="container enquiry">
      <div className="main-heading">
        <h1>
          E<span>nquire No</span>w
        </h1>
      </div>
      <div className="my-4 lg:p-5 p-3 w-full lg:w-8/12 bg-enquiry rounded-[10px] mx-auto">
        <form onSubmit={handleSubmit} className="flex flex-wrap">
          <div className="w-full md:w-6/12 p-2">
            <div>
              <label
                htmlFor="name"
                className="block font-poppins font-medium text-lg mb-1"
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
                placeholder="Name"
                required
              />
            </div>
          </div>
          <div className="w-full md:w-6/12 p-2">
            <div>
              <label
                htmlFor="phoneNo"
                className="block font-poppins font-medium text-lg mb-1"
              >
                Phone No.
              </label>
              <input
                type="text"
                name="phoneNo"
                value={formData.phoneNo}
                onChange={handleChange}
                className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
                placeholder="Phone no"
                required
              />
            </div>
          </div>
          <div className="w-full md:w-6/12 p-2">
            <div>
              <label
                htmlFor="email"
                className="block font-poppins font-medium text-lg mb-1"
              >
                Email
              </label>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
                placeholder="Email"
                required
              />
            </div>
          </div>
          <div className="w-full md:w-6/12 p-2">
            <div>
              <label
                htmlFor="address"
                className="block font-poppins font-medium text-lg mb-1"
              >
                Address
              </label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
                placeholder="Address"
                required
              />
            </div>
          </div>
          <div className="w-full p-2">
            <div>
              <label
                htmlFor="message"
                className="block font-poppins font-medium text-lg mb-1"
              >
                Message
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
                placeholder="Message"
                required
              ></textarea>
            </div>
          </div>
          <div className="p-2">
            <button
              type="submit"
              className="rounded-[10px] bg-enquiry-button font-poppins font-medium text-white text-lg py-1 px-4"
            >
              Send
            </button>
          </div>
          {error && <div className="p-2 text-red-500">{error}</div>}
          {success && <div className="p-2 text-green-500">{success}</div>}
        </form>
      </div>
    </div>
  );
};

export default EnquiryForm;
