import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import axios from 'axios';
import { useAuth } from '../../AuthContext';

const CareerEdit = () => {
  const { careerId } = useParams();
  const { apipath, filepath } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    phoneNo: '',
    email: '',
    category: '',
    message: '',
    resume: '',
  });

  useEffect(() => {
    const fetchCareerEdit = async () => {
      try {
        const response = await axios.get(`${apipath}/career/details/${careerId}`);
        setFormData(response.data.career);
      } catch (error) {
        console.error("Error fetching Career Details:", error);
      }
    };
    fetchCareerEdit();
  }, [careerId]);

  return (
    <>
      <AdminSidebar />
      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Apply Candidate Details</h1>
          </div>
          <div className="container-fluid mt-3">
            <form className="row login-form">
              <div className="mb-3 col-lg-6">
                <label htmlFor="name" className='mb-2'>Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formData.name || ''} // Ensure value is a string
                  readOnly
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="category" className='mb-2'>Category</label>
                <input
                  type="text"
                  className="form-control"
                  name="category"
                  value={formData.category || ''} // Ensure value is a string
                  readOnly
                />
              </div>

              <div className="mb-3 col-lg-6">
                <label htmlFor="email" className='mb-2'>Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={formData.email || ''} // Ensure value is a string
                  readOnly
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="phoneNo" className='mb-2'>Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="phoneNo"
                  value={formData.phoneNo || ''} // Ensure value is a string
                  readOnly
                />
              </div>

              <div className="mb-3 col-lg-12">
                <label htmlFor="message" className='mb-2'>Message</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="message"
                  value={formData.message || ''} // Ensure value is a string
                  readOnly
                />
              </div>

              <div className="change-profile pt-4 pb-4">
                <h6>Candidate Resume</h6>
                <input
                  hidden
                  type="file"
                  id="fileInput"
                  name="resume"
                  style={{ display: "none" }}
                  img src={ formData.resume !== '' && formData.resume !== null ? filepath +formData.resume : `${process.env.PUBLIC_URL}/assets/career.pdf`} alt="resume"
                />
              </div>
              <div className="submit-box pt-4">
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerEdit;
