import React, { useEffect, useRef, useState } from "react";
import AdminSidebar from './AdminSidebar'
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import iziToast from 'izitoast';

const CareerDetails = () => {

  const navigate = useNavigate();
  const { apipath, filepath } = useAuth();
  const [careerData, setCareerData] = useState([]);

  // Function to fetch career data
  const fetchData = async () => {
    try {
      const response = await axios.get(`${apipath}/career/details`);
      setCareerData(response.data.career || []);
    } catch (error) {
      console.error('Error fetching career:', error);
    }
  };

  // Function to handle career deletion
  const handleDelete = async (careerId) => {
    try {
      const response = await axios.delete(`${apipath}/career/details/${careerId}`);

      if (response.status === 200) {
        // Update the state to remove the deleted product from the UI
        setCareerData(prevCareers => prevCareers.filter(career => career._id !== careerId));
        iziToast.success({
          message: "Details deleted successfully",
          position: "topCenter",
        });
        navigate("/careerDetails");
      } else {
        iziToast.error({
          message: "Details deletion failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error('Error deleting Details:', error);
      iziToast.error({
        message: "An error occurred while deleting the Details",
        position: "topCenter",
      });
    }
  };


  const handleUpdate = async (careerId) => {
    try {
      // Redirect to the user details page with the careerId as a parameter
      navigate(`/careerEdit/${careerId}`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <AdminSidebar />
      <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Apply Candidate Lists</h1>
          </div>

          <div className="container-fluid">
            <div className='filter-flex'>
              <div class="input-group search-group">
                <div class="input-group-prepend search-prepend">
                  <span class="input-group-text search-text" id="basic-addon1">
                    <i className="bi bi-search"></i>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control search-control"
                  placeholder="Search by name"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column">
                <div className="container mt-4">
                  <table id="datatable" className="table">
                    <thead>
                      <tr className="tr1">
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Email Id</th>
                        <th>Category</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {careerData.map((career, index) => (
                        <tr key={career._id}>
                          <td>{index + 1}</td>
                          <td>{career.name}</td>
                          <td>{career.phoneNo}</td>
                          <td>{career.email}</td>
                          <td>{career.category}</td>
                          <td>
                            <button className="table-btn me-2"
                              onClick={() => handleUpdate(career._id)}>
                              <i className="bi bi-pencil"></i>
                            </button>
                            <button className="table-btn" onClick={() => handleDelete(career._id)}>
                              <i className="bi bi-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CareerDetails