import React, { useState } from 'react';
import AdminSidebar from '../admin/AdminSidebar';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import iziToast from 'izitoast';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

const PlanPriceAdd = () => {
  const { apipath } = useAuth();
  const [editorData, setEditorData] = useState('');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    plansHeading: '',
    plansText: '',
    plansPrice: '',
  });
  const [planItems, setPlanItems] = useState([{ id: 1, content: '', file: null }]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const dataToSend = {
      ...formData,
      plansText: editorData,
    };

    try {
      const response = await fetch(`${apipath}/plansPrice/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.status === 201) {
        iziToast.success({
          message: "Plan added successfully",
          position: "topCenter"
        });
        navigate("/planPriceDetails");
      } else {
        iziToast.error({
          message: "Plan addition failed",
          position: "topCenter"
        });
      }
    } catch (error) {
      console.error("Error adding plan:", error);
      iziToast.error({
        message: "An error occurred",
        position: "topCenter"
      });
    }
  };

  const addNewPlanItem = () => {
    setPlanItems([
      ...planItems,
      { id: planItems.length + 1, content: '', file: null }
    ]);
  };

  const handlePlanItemChange = (id, field, value) => {
    setPlanItems(planItems.map(item => item.id === id ? { ...item, [field]: value } : item));
  };

  return (
    <>
    <AdminSidebar/>
      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Add Plan</h1>
          </div>
          <div className="container-fluid mt-3">
            <form className="row login-form" onSubmit={handleRegister}>
              <div className="mb-3 col-lg-6">
                <label htmlFor="plansHeading" className='mb-2'>Plan Heading</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter plan Heading"
                  name="plansHeading"
                  value={formData.plansHeading}
                  onChange={handleChange}
                  maxLength="100"
                  required
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="plansPrice" className='mb-2'>Plan Price</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter plans price"
                  name="plansPrice"
                  value={formData.plansPrice}
                  onChange={handleChange}
                  maxLength="1000"
                  required
                />
              </div>

              <div className="mb-3 col-lg-12">
                <label htmlFor="plansText" className='mb-2'>Plan Text</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={editorData}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setEditorData(data);
                  }}
                />
              </div>
            </form>

            <div className="add-new-box">
              <form className="row">
                {planItems.map((item, index) => (
                  <div key={index} className="col-lg-6 plan-column">
                    <label htmlFor={`file-${item.id}`}>Content</label>
                    <input
                      type="file"
                      className='form-control mb-2'
                      accept=".png, .jpg, .jpeg"
                      id={`file-${item.id}`}
                      onChange={(e) => handlePlanItemChange(item.id, 'file', e.target.files[0])}
                      required
                    />
                    <input
                      type="text"
                      className='form-control mb-2'
                      value={item.content}
                      onChange={(e) => handlePlanItemChange(item.id, 'content', e.target.value)}
                      required
                    />
                    <textarea
                      className='form-control mb-2'
                      rows={4}
                      value={item.content}
                      onChange={(e) => handlePlanItemChange(item.id, 'content', e.target.value)}
                      required
                    />
                  </div>
                  
                ))}
                            <div className="add-new-box-btn pt-4">
              <button type="button" onClick={addNewPlanItem}>Add New Item</button>
            </div>
              </form>
            </div>

<div className="submit-planbtn pt-5">
  <button> Submit </button>
</div>

          </div>
        </div>
      </div>
    </>
  );
};

export default PlanPriceAdd;
