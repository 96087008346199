// import React, { createContext, useContext, useState } from "react";

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const apipath = "https://narayanaluminium.com:6060/api/v1";
//   // const apipath = "http://localhost:4000/api/v1";

//   // const filepath = "http://localhost:4000/images/";
//   const filepath = "https://narayanaluminium.com:6060/images/";
//   const login = () => {
//     setIsLoggedIn(true);
//     // Additional login logic (e.g., setting tokens, etc.)
//   };

//   const logout = () => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("name");
//     localStorage.removeItem("userId");
//     localStorage.removeItem("email");
//     setIsLoggedIn(false);
//     window.location.href = "/";
//     // Additional logout logic (e.g., clearing tokens, etc.)
//   };
//   const adminLogout = () => {
//     localStorage.removeItem("admintoken");
//     localStorage.removeItem("adminname");
//     localStorage.removeItem("adminemail");
//     localStorage.removeItem("adminId");
//     setIsLoggedIn(false);
//     window.location.href = "/";
//     // Additional logout logic (e.g., clearing tokens, etc.)
//   };

//   return (
//     <AuthContext.Provider
//       value={{ isLoggedIn, apipath, login, logout, adminLogout, filepath }}
//     >
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => useContext(AuthContext);

import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const apipath = "https://narayanaluminium.com:6060/api/v1";
  const filepath = "https://narayanaluminium.com:6060/images/";

  // const apipath = "https://narayanaluminium.dgtlinnovations.in:8080/api/v1";
  // const filepath = "https://narayanaluminium.dgtlinnovations.in:8080/images/";

  // const apipath = "http://localhost:6060/api/v1";
  // const apipath = "http://localhost:4000/api/v1";
  // const filepath = "http://localhost:4000/images/";

  const login = () => {
    setIsLoggedIn(true);
    // Additional login logic (e.g., setting tokens, etc.)
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    setIsLoggedIn(false);
    window.location.href = "/";
    // Additional logout logic (e.g., clearing tokens, etc.)
  };
  const adminLogout = () => {
    localStorage.removeItem("admintoken");
    localStorage.removeItem("adminname");
    localStorage.removeItem("adminemail");
    localStorage.removeItem("adminId");
    setIsLoggedIn(false);
    window.location.href = "/";
    // Additional logout logic (e.g., clearing tokens, etc.)
  };

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        logout();
      }
    }
  };

  useEffect(() => {
    checkTokenExpiration();
    const interval = setInterval(() => {
      checkTokenExpiration();
    }, 60000); // Check every 1 minute

    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, apipath, login, logout, adminLogout, filepath }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
