// import React from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";
// import EnquiryForm from "./EnquiryForm";

// const Mission = () => {
//   return (
//     <>
//       <Navbar />

//       <Link to="/" className="float">
//         <img src="imgs-alu/whtsp.png" alt="" />
//       </Link>

//       <Link to="/" className="float2" title="Help">
//         <img src="imgs-alu/help.png" alt="" />
//       </Link>

//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//             Our Mission
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home{" "}
//             <i className="bi bi-chevron-double-right text-light-yellow"></i>
//             Our Mission
//           </h4>
//         </div>
//       </div>

//       <div className="container py-5">
//         <img src="imgs-alu/company.png" alt="" className="w-full" />
//       </div>

//       <div className="container-fluid py-5 bg-light-blue mb-5">
//         <div className="main-heading">
//           <h1>
//             O<span>ur Missio</span>n
//           </h1>
//         </div>
//         <div className="w-full lg:w-9/12 mx-auto flex flex-wrap">
//           <div className="w-full lg:w-6/12 p-2">
//             <div className="p-4 shadow-custom-3 bg-light-blue-2 rounded-[10px]">
//               <div className="flex items-center">
//                 <img src="imgs-alu/quality 1.png" alt="" />
//                 <h4 className="font-exo font-semibold text-[22px] text-regal-blue ml-4">
//                   Excellent Quality
//                 </h4>
//               </div>
//               <p className="font-nunito font-medium text-[15px] leading-6 px-3 mt-3">
//                 Due to wide variations in raw material quality, producing
//                 aluminum at competitive rates and with minimal environmental
//                 impact can be difficult. Throughout the whole aluminum value
//                 chain, from sourcing raw materials to processing aluminum and
//                 reaching the end customer, we aim for efficiency and quality.
//               </p>
//             </div>
//           </div>
//           <div className="w-full lg:w-6/12 p-2">
//             <div className="p-4 shadow-custom-3 bg-light-blue-2 rounded-[10px]">
//               <div className="flex items-center">
//                 <img src="imgs-alu/quality 2.png" alt="" />
//                 <h4 className="font-exo font-semibold text-[22px] text-regal-blue ml-4">
//                   Innovation & Technology
//                 </h4>
//               </div>
//               <p className="font-nunito font-medium text-[15px] leading-6 px-3 mt-3">
//                 For the aluminum sector, technological advancement and
//                 innovation are essential. They make it possible to produce
//                 materials and products with improved quality and increased
//                 value. This improves people's quality of life while increasing
//                 productivity, profitability, and corporate growth.
//               </p>
//             </div>
//           </div>
//           <div className="w-full lg:w-6/12 p-2">
//             <div className="p-4 shadow-custom-3 bg-light-blue-2 rounded-[10px]">
//               <div className="flex items-center">
//                 <img src="imgs-alu/quality 3.png" alt="" />
//                 <h4 className="font-exo font-semibold text-[22px] text-regal-blue ml-4">
//                   Basic Value- Integrity
//                 </h4>
//               </div>
//               <p className="font-nunito font-medium text-[15px] leading-6 px-3 mt-3">
//                 At Narayan Aluminium Alloys Pvt. Ltd, integrity is a basic
//                 value. The Company expects respect, fairness, and integrity from
//                 its workers, customers, partners, suppliers, investors, and the
//                 public in general. The Company expects its staff to uphold the
//                 highest ethical standards.
//               </p>
//             </div>
//           </div>
//           <div className="w-full lg:w-6/12 p-2">
//             <div className="p-4 shadow-custom-3 bg-light-blue-2 rounded-[10px]">
//               <div className="flex items-center">
//                 <img src="imgs-alu/quality 4.png" alt="" />
//                 <h4 className="font-exo font-semibold text-[22px] text-regal-blue ml-4">
//                   Basic Value- Integrity
//                 </h4>
//               </div>
//               <p className="font-nunito font-medium text-[15px] leading-6 px-3 mt-3">
//                 Our health and safety management system include leadership
//                 accountability, hazard identification, training, and employee
//                 empowerment. As a company, we collaborate to identify and
//                 eliminate potential risks, and we expect every employee to
//                 participate in this process.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>

//       <EnquiryForm/>

//       <Footer />
//     </>
//   );
// };

// export default Mission;


import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useSearchParams } from "react-router-dom";
import EnquiryForm from "./EnquiryForm";
import { useAuth } from "../../AuthContext";
import axios from 'axios'

const Mission = () => {
  const { apipath, filepath } = useAuth();
  const [missionData, setMissionData] = useState(null);
  const [searchParams] = useSearchParams();
  const pageType = searchParams.get("pageType") || "Default";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/aboutcms/content/${pageType}`);
        // Log the data portion of the response
        //  console.log('Response Data:', response.data);
        setMissionData(response.data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, [pageType]);

  if (!missionData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <Link to="/" className="float2" title="Help">
        <img src="imgs-alu/help.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Our Mission
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            Our Mission
          </h4>
        </div>
      </div>

      <div className="container py-5">
        <img src="imgs-alu/company.png" alt="" className="w-full" />
      </div>

      <div className="container-fluid py-5 bg-light-blue mb-5">
        <div className="main-heading">
          <h1>
            <span>{missionData.maintitle}</span>
          </h1>
        </div>
        <div className="w-full lg:w-9/12 mx-auto flex flex-wrap">
        {missionData.body1card.map((item, index) => (  
          <div className="w-full lg:w-6/12 p-2" key={index}>
            <div className="p-4 shadow-custom-3 bg-light-blue-2 rounded-[10px]">
              <div className="flex items-center">
                <img src={filepath + item.cardimage} alt="" />
                <h4 className="font-exo font-semibold text-[22px] text-regal-blue ml-4">
                {item.cardtitle}
                </h4>
              </div>
              <p className="font-nunito font-medium text-[15px] leading-6 px-3 mt-3">
              {item.cardcontent}
              </p>
            </div>
          </div>
          ))}
        </div>
      </div>

      <EnquiryForm/>

      <Footer />
    </>
  );
};

export default Mission;
